import { getAuth } from "firebase/auth";
import correctEndpointUrl from "../../constants/firebaseEndpoint";

export default async (organizationId, userId) => {
  const response = await fetch(`${correctEndpointUrl}/attachOrgToUserAuthState`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify({ organizationId, userId }),
  });

  const user = getAuth().currentUser;

  // Forcing custom token refresh
  await user.getIdToken(true);

  return response.json();
};
