export default {
  title: "Analyzuji vaše cvičení a připravuji výsledky.",
  needMoreTime: "Ještě minutku, dokončuji analýzu. Děkuji za trpělivost",
  aiTip: "Můj Tip pro tebe",
  finish: "Dokončil jsem analýzu!",
  buttons: {
    view: "Zobrazit mojí analýzu",
    nextTip: "Další tip",
  },
};
