export default {
  buttons: {
    continue: "Pokračovat",
    back: "Zpět",
    backToPaths: "Zkusit jinou lekci",
    replayPath: "Opakovat lekci",
    backToJourney: "Zpět do lekce",
    viewFullLesson: "Zobrazit plnou lekci",
    buy: "Koupit",
    analyzeJourney: "Analyzovat lekci",
    showJourneyAnalysis: "Zobrazit analýzu lekce",
    downloadPdf: "Stáhnout v PDF",
  },
  messages: {
    finish: "Úspěšně jste dokončili lekci:",
    stepLocked: "Tento krok je uzamčený.",
    initiated: "Spotřebovali jste {credits} kreditů",
  },
  titles: {
    learningPath: "Lekce",
    finish: "Blahopřejeme!",
    analysis: "Analýza vaší lekce",
    keyTakeaways: "Hlavní body",
    strengths: "Silné stránky",
    weaknesses: "Oblasti pro zlepšení",
    completionOverview: "Přehled dokončení lekce",
    exerciseCompletion: "Dokončení cvičení",
    exercises: "Cvičení",
    noLessonAnalysisFound: "Nebyla nalezena žádná analýza lekce",
  },
  gameHistoryList: {
    name: "Název",
    type: "Typ",
    startedAt: "Započato",
    endedAt: "Dokončeno",
    status: "Stav",
    userInfo: "Informace o uživateli",
    userName: "Jméno",
    lessonCompletion: "Dokončení lekce",
  },
};
