export default {
  types: {
    short: "Short",
    long: "Long",
  },
  buttons: {
    changeGame: "Change Game",
    analyzeConversation: "Analyze Exercise",
    assignment: "Assignment",
    close: "Close",
    coach: "Help Me",
    otherOptions: "Other Options",
    copy: "Copy",
    newExercise: "Change Exercise",
  },
  warnings: {
    minNumberOfMessagesForShortAnalysis: "You must send at least one message to analyze the exercise.",
    minNumberOfMessagesForLongAnalysis: "You must send at least 2 messages to analyze the exercise.",
  },
  placeholders: {
    writeMessage: "Write a message...",
  },
  titles: {
    assignment: "Assignment",
    goal: "Exercise",
    goalDescription: "Your Goal",
    getNewExercise: "Try another exercise",
    analysisConfirmModal: "Do you really want to finish the exercise and analyze it?",
  },
};
