export default {
  buttons: {
    play: "Play",
    detail: "Detail",
  },
  meetingTypes: {
    video: "Video meeting",
    inPerson: "In person meeting",
    call: "Call",
  },
  difficultyTypes: {
    true: "Hard",
  },
  descriptionItems: {
    oponentDescription: "Your opponent",
    situationDescription: "Situation",
    goalDescription: "Goal",
  },
  trainedCompetences: "Trained competences",
};
