export default {
  title: "We are analyzing your exercise and preparing results.",
  needMoreTime: "Just a minute, I'm finishing the analysis. Thank you for your patience",
  aiTip: "My Tip For You",
  finish: "I have finished the analysis!",
  buttons: {
    view: "View My Analysis",
    nextTip: "Next Tip",
  },
};
