export default {
  titles: {
    success: "Congratulations! You have finished your exercise.",
    fail: "Let's try that again.",
  },
  messages: {
    success: "Let's analyze your exercise.",
    fail: "Unfortunately you have failed the exercise, but dont worry, you can try again!",
    failToAnalysis: "Unfortunately you have failed the exercise, but don't worry! Let's analyze your exercise and see what you could have done better.",
    analysisRequirement: "You have to send at least 2 messages to analyze the exercise.",
    limitedTimeOffer: "Limited time offer!",
    moneyBackGuarantee: "Money back guarantee!",
  },
  buttons: {
    repeatGame: "Repeat Exercise",
    analyzeGame: "Analyze Exercise",
    newGame: "New Exercise",
    unlockLesson: "Buy Full Lesson",
  },
};
