import { ref } from "vue";
import { defineStore } from "pinia";
import {
  getAuth,
  updatePassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  getDatabase, ref as dbRef, child, get, set, onValue,
} from "firebase/database";
import logInUser from "../methods/firebase/logInUser";
import logOutUser from "../methods/firebase/logOutUser";
import getAuthenticatedUser from "../methods/firebase/getAuthenticatedUser";
import {
  aiProfilesRoute, correctOrganizationsRoute, correctUsersRoute, gamesRoute, gymsRoute, journeysRoute,
} from "../constants/dbRoutes";
import { personalAppOrganizationId } from "../constants/general";
import sanitizeObject from "../methods/utils/sanitizeObject";
import moduleTypes from "../constants/moduleTypes";
import { conversationEndNegativeToken, conversationEndToken } from "../constants/chat";
import getUserGroupsPerOrganization from "../methods/firebase/getUserGroupsPerOrganization";
import { handleErrorTracking } from "../middleWare/errorTracker";
import errorFeatureIds from "../constants/errorFeatureIds";
// import addNewUserCredits from "../methods/firebase/addNewUserCredits";

export default defineStore("user", () => {
  const db = getDatabase();
  const dbReference = dbRef(db);

  // state
  /**
   * @description User data
   * @property {string} firstName
   * @property {string} lastName
   * @property {string} email
   * @property {uuid} id
   * @property {string} role
   * @property {string} avatar
   * @property {string} memberDescription
   * @property {string} memberImage
   * @property {string} phone
   * @property {string} title
   * @property {string} dateOfBirth
   * @property {boolean} disabled
   * @property {boolean} hasAcceptedTerms
   * @property {
   *  [organizationId]: {number} Tokens available per organization
   * } creditsPerOrganization
   * @property {
   *  [organizationId]: {number} Tokens used per organization
   * } usedCreditsPerOrganization
   * @property {
   *  [gameId]: {
   *    id: string - game id
   *    module_type: string - game type
   *    status: string - game status
   *   }
   *  } games
   *
   */
  const userData = ref({});
  const hierarchyLevel = ref(null);
  const loadedUserGames = ref([]);
  const loadedUserJourneys = ref([]);
  const isSimpleSession = ref(window.location.host.split(".")?.[0] === "simple");
  let userValueBeingTracked = false;

  // actions
  const getUserOrganizations = async (organizationIds, userId) => {
    const organizations = await Promise.all(
      [
        ...(organizationIds || []),
        ...(organizationIds.includes(personalAppOrganizationId) ? [] : [personalAppOrganizationId]), // Adds personal app organization id if it's not already in the list
      ].map(async (organizationId) => {
        const organizationName = (await get(child(dbReference, `${correctOrganizationsRoute}/${organizationId}/public/name`))).val();
        const memberHierarchyLevel = (await get(child(dbReference, `${correctOrganizationsRoute}/${organizationId}/private/memberHierarchyLevels/${userData.value.id || userId}`))).val();

        return {
          id: organizationId,
          name: organizationName,
          hierarchyLevel: memberHierarchyLevel,
        };
      }),
    );

    return organizations.filter(({ name, id }) => name && id);
  };

  const getGroupsPerOrganization = async (organizationId) => {
    const groups = getUserGroupsPerOrganization({
      userId: userData.value.id,
      organizationId,
    });

    return groups;
  };

  const fetchUserJourneys = async (pageNumber) => {
    const userId = userData.value.id;
    const userJourneys = (await get(child(dbReference, `${correctUsersRoute}/${userId}/${journeysRoute}`))).val();
    const gamesPerPage = 20;
    const start = (pageNumber - 1) * gamesPerPage;
    const end = start + gamesPerPage;
    // Reverse to get the latest journeys first
    const journeysToLoad = Object.keys(userJourneys || {}).reverse().slice(start, end);

    const loadedJourneys = await Promise.all(
      journeysToLoad.map(async (journeyId) => {
        try {
          return (await get(child(dbReference, `${journeysRoute}/${journeyId}`))).val();
        } catch {
          return null;
        }
      }),
    );

    loadedUserJourneys.value = loadedJourneys
      .filter((journey) => journey);
  };

  const fetchUserGames = async (pageNumber) => {
    const userId = userData.value.id;
    const userGames = (await get(child(dbReference, `${correctUsersRoute}/${userId}/${gamesRoute}`))).val();
    const gamesPerPage = 20;
    const start = (pageNumber - 1) * gamesPerPage;
    const end = start + gamesPerPage;
    // Reverse to get the latest games first
    const games = Object.keys(userGames || {}).reverse().slice(start, end);
    const fetchedProfiles = {};

    const loadedGames = await Promise.all(
      games.map(async (gameId) => {
        const game = (await get(child(dbReference, `${gamesRoute}/${gameId}`))).val();

        if (game) {
          const gameModuleType = game.moduleType;
          const gameEntityId = game.aiIdentityId;
          let gameProfile = fetchedProfiles[gameEntityId];

          if (!gameProfile) {
            gameProfile = (await get(child(dbReference, `${moduleTypes.challange ? aiProfilesRoute : gymsRoute}/${gameEntityId}/public`))).val();
            fetchedProfiles[gameEntityId] = gameProfile;
          }

          return gameProfile ? {
            id: gameId,
            moduleType: gameModuleType,
            aiIdentityId: gameEntityId,
            startedAt: game.startedAt,
            status: game.status,
            name: gameProfile?.name,
            numberOfMessages: game?.messages?.length || 0,
            lastMessage: (game?.messages?.[(game?.messages?.length || 1) - 1]?.content || "").replace(conversationEndToken, "").replace(conversationEndNegativeToken, ""),
          } : null;
        } return null;
      }),
    );

    loadedUserGames.value = [
      ...loadedUserGames.value,
      ...loadedGames
        .filter((game) => game),
    ];
  };

  const assignCorrectUserData = async (user) => {
    userData.value = {
      ...user,
      organizations: await getUserOrganizations(Object.keys(user.creditsPerOrganization || {}), user.id),
    };
  };

  const loadUserData = async (uid) => {
    const userSnapshot = await get(child(dbReference, `${correctUsersRoute}/${uid}`));
    const loadedUser = userSnapshot?.val();

    if (!userValueBeingTracked) {
      const userRef = dbRef(db, `${correctUsersRoute}/${uid}`);

      onValue(userRef, async (snapshot) => {
        const data = snapshot.val();

        await assignCorrectUserData({
          ...userData.value,
          ...data,
        });
      });

      userValueBeingTracked = true;
    }

    return loadedUser;
  };

  const setUserData = async (data) => {
    if (!data) {
      userData.value = {};
      return;
    }

    const loadedUser = await loadUserData(data.uid);

    await assignCorrectUserData({
      ...(data || {}),
      ...loadedUser,
    });
  };

  const getOrganizationEmail = async (organizationId) => {
    const organizationEmailSnapshot = await get(child(dbReference, `organizationToEmailMaps/${organizationId}`));
    const organizationEmail = organizationEmailSnapshot?.val();

    return organizationEmail;
  };

  const logOut = async function () {
    await logOutUser();
    userData.value = {};
  };

  const login = handleErrorTracking(async (email, password, provider) => {
    const userInfo = await logInUser(email, password, provider);

    if (!userInfo) return;

    const loadedUser = await loadUserData(userInfo.uid);

    await assignCorrectUserData({
      ...(userInfo || {}),
      ...loadedUser,
    });
  }, {
    feature: errorFeatureIds.auth.login,
    measurePerformance: true,
    hideArguments: true,
    extraData: ([email, , provider]) => ({
      provider,
      email,
    }),
  });

  const createUserWithEmail = handleErrorTracking(
    async function (userInfo) {
      const auth = getAuth();
      const userCredentials = await createUserWithEmailAndPassword(auth, userInfo.email, userInfo.password);

      if (!userCredentials.user) {
        return false;
      }
      // Sets user data to database
      const data = sanitizeObject({
        email: userInfo.email || "",
        firstName: userInfo.firstName || "",
        lastName: userInfo.lastName || "",
        id: userCredentials.user.uid,
        createdAt: new Date().toISOString(),
      });

      this.updateTracking({
        email: userInfo.email,
        userId: userCredentials.user.uid,
      });

      await set(dbRef(db, `${correctUsersRoute}/${userCredentials.user.uid}`), data);

      // TODO rework this
      // addNewUserCredits(userCredentials.user.uid);

      if (userCredentials.user) {
        await login(userInfo.email, userInfo.password);
      }

      return true;
    },
    {
      feature: errorFeatureIds.auth.register,
      measurePerformance: true,
      hideArguments: true,
      trackSuccess: true,
    },
  );

  const checkLogInStatus = handleErrorTracking(
    async () => {
      if (!window.firebaseInitialized) {
        await new Promise((resolve) => {
          const firabseInicialiazationInterval = setInterval(() => {
            if (window.firebaseInitialized) {
              clearInterval(firabseInicialiazationInterval);
              resolve();
            }
          }, 100);

          setTimeout(() => {
            clearInterval(firabseInicialiazationInterval);
            resolve();
          }, 5000);
        });
      }

      const auth = getAuth();
      const user = await new Promise((resolve) => {
        onAuthStateChanged(auth, (authUser) => {
          if (authUser) resolve(authUser);
          else resolve(null);
        });
      });

      if (user?.uid) {
        await setUserData(user);

        return true;
      }

      return false;
    },
    {
      feature: errorFeatureIds.auth.methods.checkLogInStatus,
      measurePerformance: true,
    },
  );

  const changePassword = handleErrorTracking(
    async (newPassword, oldPassword, email) => {
      try {
        const auth = getAuth();

        await signInWithEmailAndPassword(auth, email, oldPassword);

        const user = getAuthenticatedUser();

        await updatePassword(user, newPassword);

        await signInWithEmailAndPassword(auth, email, newPassword);

        return true;
      } catch (error) {
        console.log(error.response, error.message);
        return false;
      }
    },
    {
      feature: errorFeatureIds.auth.methods.changePassword,
    },
  );

  const resetPassword = handleErrorTracking(
    async (email, redirectUrl) => {
      try {
        const auth = getAuth();
        const actionCodeSettings = {
          url: redirectUrl,
          handleCodeInApp: true,
        };

        await sendPasswordResetEmail(auth, email, actionCodeSettings);

        return true;
      } catch (error) {
        console.log(error.response, error.message);
        return false;
      }
    },
    {
      feature: errorFeatureIds.auth.methods.resetPassword,
    },
  );

  const confirmUserAcceptedTerms = async () => {
    set(child(dbReference, `${correctUsersRoute}/${userData.value.id}/hasAcceptedTerms`), true);

    userData.value.hasAcceptedTerms = true;
  };

  const updatePersonalInfo = handleErrorTracking(
    async (data) => {
      const sanitizedData = sanitizeObject(data);

      await Promise.all(Object.entries(sanitizedData).map(async ([key, value]) => {
        if (value !== null && value !== undefined && key) {
          await set(child(dbReference, `${correctUsersRoute}/${userData.value.id}/${key}`), value);
        }
      }));

      await setUserData({
        ...userData.value,
        ...sanitizedData,
      });
    },
    {
      feature: errorFeatureIds.auth.methods.updatePersonalInfo,
    },
  );

  const setUserHierarchyLevel = handleErrorTracking(
    async function (organizationId) {
      this.updateTracking({
        route: `${correctOrganizationsRoute}/${organizationId}/private/memberHierarchyLevels/${userData.value.id}`,
        organizationId,
      });

      const userHierarchyLevel = (await get(child(dbReference, `${correctOrganizationsRoute}/${organizationId}/private/memberHierarchyLevels/${userData.value.id}`))).val();

      hierarchyLevel.value = userHierarchyLevel;
    },
    {
      feature: errorFeatureIds.auth.methods.setUserHierarchyLevel,
    },
  );

  // getters
  const getUserData = () => userData.value;

  return {
    login,
    logOut,
    checkLogInStatus,
    changePassword,
    getUserData,
    getOrganizationEmail,
    setUserData,
    resetPassword,
    confirmUserAcceptedTerms,
    createUserWithEmail,
    updatePersonalInfo,
    fetchUserGames,
    fetchUserJourneys,
    setUserHierarchyLevel,
    getGroupsPerOrganization,
    loadedUserJourneys,
    loadedUserGames,
    userData,
    hierarchyLevel,
    isSimpleSession,
  };
});
