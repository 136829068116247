import correctEndpointUrl from "../../constants/firebaseEndpoint";

export default async ({
  user, language,
}) => {
  const response = await fetch(`${correctEndpointUrl}/sendRegistrationMail`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      user, language,
    }),
  });

  return response.json();
};
