export const correctOrganizationsRoute = "organizations";
export const correctGroupsRoute = "groups";
export const correctUsersRoute = "users";
export const competencesRoute = "competences";
export const aiProfilesRoute = "aiProfiles";
export const gymsRoute = "gyms";
export const gamesRoute = "games";
/** The same route is also for the storage */
export const microlearningsRoute = "microlearnings";
export const learningPathsRoute = "paths";
export const journeysRoute = "journeys";
export const tipsRoute = "tips";
