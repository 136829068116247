export default {
  baseInfo: "Base info",
  name: "Name",
  unknownName: "Unknown name",
  goal: "Exercise",
  unknownGoal: "Unknown goal",
  user: "User",
  ai: "AI",
  score: "score",
  observableBehaviours: "Your behaviour in the area",
  recommendations: "Recommendations",
  analysis: "Analysis",
  conversationRating: "Analysis of your conversation",
  yourConversation: "Your conversation",
  yourAnalysis: "Your analysis",
  yourJourneyAnalysis: "Your lesson analysis",
  fileName: "Conversation analysis",
  journeyFileName: "Lesson analysis",
  notes: "Notes & Next Steps",
};
