export default {
  messages: {
    notEnoughCredits: "Nemáte dostatek kreditů na provedení této akce. Přidejte prosím kredity do svého účtu.",
    unableToAnalyzeConversation: "Nepodařilo se nám analyzovat konverzaci.",
    unableToAnalyzeJourney: "Nepodařilo se nám analyzovat lekci.",
    inappropriateContent: "Obsah vašich zpráv nebo zprávy byl nevhodný.",
    responseTooLong: "Odpověď byla příliš dlouhá.",
    responseSlow: "Odpověď byla příliš pomalá.",
    serverError: "Nastala chyba na serveru.",
    serverErrorUpdatingCredits: "Při aktualizaci kreditů člena došlo k chybě.",
    maxMessagesReached: "Bylo dosaženo maximálního počtu zpráv.",
    gameEnded: "Hra byla již ukončena, nemůžete v konverzaci dále pokračovat.",
    nothingToAnalyze: "Nemáte žádné zprávy k analýze.",
    generalChatError: "Při odesílání nastala chyba. Zkuste zprávu odeslat znovu, případně ji zkuste zkrátit.",
    tokenDelay: "Server přestal odpovídat. Zkuste zprávu odeslat znovu, případně stránku znovu načtěte.",
    emailNotVerified: "Váš e-mail nebyl zatím ověřen. Ověřte prosím svůj e-mail a zkuste to znovu.",
    paymentsSessionCreationFailed: "Nepodařilo se vytvořit platební relaci. Zkuste to prosím znovu.",
    minCreditAmountNotReached: "Minimální částka kreditů ve výši {minNumberOfCredits} nebyla dosažena.",
    notEnoughCreditsToStartLesson: "Nemáte dostatek kreditů na zahájení této lekce.",
    userNotFound: "Uživatel nebyl nalezen.",
    journeyNotFound: "Lekce nebyla nalezena.",
    journeyEnded: "Lekce byla již ukončena, nemůžete ve cvičení dále pokračovat.",
    journeyStepsNotFound: "Lekce nebyla nalezena.",
    unableToGenerateJourneyAnalysis: "Nemáme dostatek informací k vygenerování analýzy lekce.",
    unableToLoadOrganization: "Nepodařilo se načíst data organizace.",
    invalidInput: "Špatný formát hodnoty",
    unableToFetchLearningPaths: "Nepodařilo se načíst lekce, obnovte prosím stránku.",
    logoutIssue: "Odhlášení se nezdařilo. Zkuste to prosím znovu.",
    unknownError: "Nastala neznámá chyba.",
    gameLoadIssue: "Nepodařilo se načíst hru. Zkuste to prosím znovu.",
    organizationModulesIssue: "Nepodařilo se načíst moduly organizace. Zkuste to prosím znovu.",
    unableToLoadMicrolearnings: "Nepodařilo se načíst mikrolearningy obnovte prosím stránku.",
    unableToLoadGym: "Nepodařilo se načíst cvičení obnovte prosím stránku.",
    unableToLoadGyms: "Nepodařilo se načíst cvičení obnovte prosím stránku.",
    unableToStreamRespone: "Nepodařilo se získat odpověď.",
    unableToGetAudioResponse: "Nepodařilo se získat audio odpověď.",
    unableToFetchProfile: "Nepodařilo se načíst hry, obnovte prosím stránku.",
    unableToFetchLearningPath: "Nepodařilo se načíst lekci, obnovte prosím stránku.",
    unableToStartLesson: "Nepodařilo se zahájit lekci.",
    unableToLoadLearningPath: "Nepodařilo se načíst lekci obnovte prosím stránku.",
    unableToStartGame: "Nepodařilo se zahájit hru.",
    unableToEndJourney: "Nepodařilo se ukončit lekci.",
  },
  user: {
    noAccessToOrganization: "Nemáte přístup k této organizaci.",
  },
  buttons: {
    reSendVerificationMail: "Znovu odeslat ověřovací e-mail",
  },
  firebase: {
    "auth/invalid-credential": "Špatný email nebo heslo.",
    "auth/user-not-found": "Uživatel nebyl nalezen.",
    "auth/wrong-password": "Neplatné heslo.",
    "auth/too-many-requests": "Příliš mnoho požadavků. Zkuste to prosím později.",
    "auth/email-already-in-use": "Tento e-mail je již používán.",
    "auth/invalid-email": "Neplatný e-mail.",
    "auth/operation-not-allowed": "Tato operace není povolena.",
    "auth/popup-blocked": "Okno pro přihlášení bylo zablokováno vaším prohlížečem.",
    "auth/quota-exceeded": "Překročili jste povolený počet pokusů o přihlášení.",
  },
};
