export default {
  "question mark": "?",
  "exclamation mark": "!",
  questionmark: "?",
  exclamationmark: "!",
  comma: ",",
  dot: ".",
  colon: ":",
  semicolon: ";",
  dash: "-",
  "quotation marks": "\"",
  quotationmarks: "\"",
  apostrophe: "'",
  bracket: "(",
  parenthesis: "(",
  "open parenthesis": "(",
  "close parenthesis": ")",
  openparenthesis: "(",
  closeparenthesis: ")",
  "open bracket": "(",
  "close bracket": ")",
  openbracket: "(",
  closebracket: ")",
};
