export default {
  about: "About",
  content: "Content of lesson",
  steps: "No. of Steps",
  credits: "Credits",
  free: "Free",
  purchased: "Purchased",
  buttons: {
    buyAndPlay: "Buy and Start",
    continue: "Continue",
    begin: "Start Lesson",
    repeat: "Repeat Lesson",
  },
};
