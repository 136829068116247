export default {
  title: "Nákup kreditů",
  input: "Počet kreditů k zakoupení",
  insuficientCreditsInGameMessage: "Nemáte dostatek kreditů k hraní, přidejte kredity aby jste mohly pokračovat ve hře.",
  minNumberOfCreditsToPlay: "Minimální počet kreditů k hraní této hry je:",
  buttons: {
    pay: "Koupit",
    cancel: "Zrušit",
  },
};
