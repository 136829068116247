import mitt from "mitt";

export const eventTypes = {
  gameUpdated: "gameUpdated",
  gameReset: "gameReset",
  showAnalysis: "showAnalysis",
  userLoggedOut: "userLoggedOut",
};

const eventBus = mitt();

export default eventBus;
