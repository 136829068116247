export default {
  buttons: {
    download: "Download Analysis",
    reGenerate: "Re-generate Analysis",
    backToProfileSelection: "Back To Profile Selection",
    backToGymSelection: "Back To Gym Selection",
    repeatGym: "Repeat Gym",
    repeatConversation: "Repeat Conversation",
  },
  titles: {
    yourLevel: "Your level",
    yourBehavious: "Your behaviour in this area",
    recommendations: "Recommendations",
    noAnalysisFound: "No analysis found. Try to repeat the conversation.",
    yourAnalysis: "Your analysis",
  },
  levels: {
    amateur: "Amateur",
    intermediate: "Intermediate",
    expert: "Expert",
  },
};
