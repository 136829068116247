export default {
  description: `Hello! I am Provocator.ai. I will help you get better at all sorts of communication skills that you might need in your professional or personal life.

  Let's begin!`,
  disclaimerDescription: `
  Even though you will take on a role, be careful about what you say or write – not everything is allowed when communicating with the AI coach.
  Definitely do not disclose anyone's personal data, data subject to confidentiality or trade secrets. Do not disclose content that may harm an individual or company.
  <br/>
  All outputs are generated by AI, and it has its limits. You have something that AI does not have yet – consciousness and reason.
  Therefore, approach AI outputs with critical thinking. By using the app, you agree to receive marketing emails. You can unsubscribe at any time within the marketing email.
  Furthermore, by launching the application you agree to comply with the content requirements and output limits, which you can read
  <a href="https://www.provocator.ai/en/terms-of-use" rel="noopener noreferrer" class="underline" target="_blank">HERE</a>.`,
  slideTitles: {
    0: "Welcome to Provocator.ai",
    1: "Quick guide",
    2: "Choose a lesson.",
    3: "Receive Tips on How to Proceed.",
    4: "Let’s Practice! Dive Right In to the Exercises.",
    5: "Get Feedback and Try Again.",
    6: "Play a Role-play Situation and See Where You Stand.",
    7: "Need help? Ask the AI coach.",
  },
  buttons: {
    next: "Next",
    play: "Play",
    start: "Start",
    skip: "Skip",
    agree: "I Agree, Let's Play!",
  },
};
