export default {
  buttons: {
    changeGame: "Change Game",
    analyzeConversation: "Analyze exercise",
    showAnalysis: "Show Analysis",
    assignment: "Assignment",
    close: "Close",
    coach: "Help Me",
    otherOptions: "Other Options",
    copy: "Copy",
    toggleAudio: "Toggle Audio",
    togglePlayback: "Toggle Playback",
  },
  warnings: {
    minNumberOfMessagesForAnalysis: "You have to send at least 2 messages to analyze the exercise.",
  },
  placeholders: {
    writeMessage: "Write a message...",
  },
  messages: {
    messagesLeft: "You have {messagesLeft} message left. | You have {messagesLeft} messages left.",
    outOfMessages: "You have run out of messages. Get your exercise analyzed.",
    gameCompleted: "The game has already been completed.",
    termsOfUse: "You're talking to an AI, which has its limitations. Learn more about these limitations and what not to send, HERE.",
    retriesLeft: "You have {retriesLeft} game retry left. | You have {retriesLeft} game retries left.",
  },
};
