export default {
  buttons: {
    continue: "Continue",
    back: "Back",
    backToPaths: "Try Another Lesson",
    replayPath: "Replay Lesson",
    backToJourney: "Back To Lesson",
    viewFullLesson: "View Full Lesson",
    buy: "Buy",
    analyzeJourney: "Analyze Lesson",
    showJourneyAnalysis: "Show Lesson Analysis",
    downloadPdf: "Download PDF",
  },
  messages: {
    finish: "You've successfully completed the lesson:",
    stepLocked: "This step is locked.",
    initiated: "You've used up {credits} credits",
  },
  titles: {
    learningPath: "Lesson",
    finish: "Congratulations!",
    analysis: "You Lesson Analysis",
    keyTakeaways: "Key Takeaways",
    strengths: "Strengths",
    weaknesses: "Areas for Improvement",
    completionOverview: "Completion Overview",
    exerciseCompletion: "Exercise Completion",
    exercises: "Exercises",
    noLessonAnalysisFound: "No lesson analysis found",
  },
  gameHistoryList: {
    name: "Name",
    type: "Type",
    startedAt: "Started At",
    endedAt: "Ended At",
    status: "Status",
    userInfo: "User Info",
    userName: "Name",
    lessonCompletion: "Lesson Completion",
  },
};
