export default {
  types: {
    short: "Krátká",
    long: "Dlouhá",
  },
  buttons: {
    changeGame: "Změnit hru",
    analyzeConversation: "Vyhodnotit cvičení",
    assignment: "Zadání",
    close: "Zavřít",
    coach: "Poraď mi",
    otherOptions: "Další možnosti",
    copy: "Zkopírovat",
    newExercise: "Jiné cvičení",
  },
  warnings: {
    minNumberOfMessagesForShortAnalysis: "Musíte odeslat alespoň jednu zprávu, abyste mohli vyhodnotit cvičení.",
    minNumberOfMessagesForLongAnalysis: "Musíte odeslat alespoň 2 zprávy, abyste mohli vyhodnotit cvičení.",
  },
  placeholders: {
    writeMessage: "Napište zprávu...",
  },
  titles: {
    assignment: "Zadání",
    goal: "Cvičení",
    goalDescription: "Tvůj cíl",
    getNewExercise: "Zkusit jiné cvičení",
    analysisConfirmModal: "Vážně chcete ukončit cvičení a vyhodnotit ho?",
  },
};
