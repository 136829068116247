import logIn from "./logIn";
import profileSelection from "./profileSelection";
import gymSelection from "./gymSelection";
import chat from "./chat";
import gym from "./gym";
import analysis from "./analysis";
import changePassword from "./changePassword";
import microlearningSelection from "./microlearningSelection";
import microlearning from "./microlearning";
import learningPathSelection from "./learningPathSelection";
import learningPath from "./learningPath";
import organizationSelection from "./organizationSelection";
import profile from "./profile";
import learningPathDetail from "./learningPathDetail";
import history from "./history";
import adminDashboard from "./adminDashboard";

export default {
  logIn,
  profileSelection,
  chat,
  analysis,
  changePassword,
  gym,
  gymSelection,
  microlearningSelection,
  microlearning,
  learningPathSelection,
  learningPath,
  organizationSelection,
  profile,
  learningPathDetail,
  history,
  adminDashboard,
};
