import { computed, ref, reactive } from "vue";
import {
  getDatabase, ref as dbRef, child, get,
} from "firebase/database";
import { defineStore } from "pinia";
import { useRoute } from "vue-router";
import getSubDomain from "../methods/utils/getSubDomain";
import { gamesRoute } from "../constants/dbRoutes";
import appSpecificSubdomains from "../constants/appSpecificSubdomains";
import { personalAppOrganizationId, testOrganizationId, unknownEntityId } from "../constants/general";
import userStore from "./user";
import getOrganizationModulesState from "../methods/firebase/getOrganizationModulesState";
import { RouteNames } from "../router/routes";
import errorFeatureIds from "../constants/errorFeatureIds";
import { handleErrorTracking } from "../middleWare/errorTracker";

/** @typedef {import('../views/Admin.vue').Ref} Ref */
/** @typedef {import('../stores/entityManagement.js').Organization} Organization */

export default defineStore("generalAppState", () => {
  const userStoreInstance = userStore();

  // DATA
  const route = useRoute();
  const dbReference = dbRef(getDatabase());
  const mobileMenuOpen = ref(false);
  const showOnboarding = ref(false);
  const organizationId = ref(null);
  const groupId = ref(null);
  const loading = ref(false);
  const isTabletSize = ref(window.innerWidth < 950);
  const isUserOnTestOrganization = ref(window.location.hostname.includes("test."));
  const organizationModuleCache = new Map();
  const CACHE_DURATION = 15 * 60 * 1000; // 15 minutes

  /**
   * Typing for a Game object.
   *
   * @typedef {Object} Game
   * @property {number} aiIdentityId - Identifier for the AI's identity.
   * @property {string} analysisResult - The result of the analysis.
   * @property {CoachMessage[]} coachMessages - An array of messages from the coach.
   * @property {string} endedAt - Timestamp when the game ended.
   * @property {string} id - Unique identifier for the game session.
   * @property {Message[]} messages - An array of messages exchanged during the game.
   * @property {string} moduleType - Type of the module, e.g., "game".
   * @property {string} startedAt - Timestamp when the game started.
   * @property {string} status - Status of the game, e.g., "completed".
   * @property {Usage} usage - Information about usage and pricing.
   * @property {string} userId - Identifier of the user who participated in the game.
   *
   * @typedef {Object} CoachMessage
   * @property {string} content - Content of the coach's message.
   * @property {string} role - Role of the sender of the message, e.g., "system".
   *
   * @typedef {Object} Message
   * @property {string} content - Content of the message.
   * @property {string} role - Role of the sender of the message, e.g., "assistant".
   *
   * @typedef {Object} Usage
   * @property {PriceForResponse} priceForResponse - Pricing information for the response.
   * @property {TokenUsage} tokenUsage - Token usage information for prompting and completion.
   *
   * @typedef {Object} PriceForResponse
   * @property {number} czk - Price in Czech korunas.
   * @property {number} usd - Price in US dollars.
   *
   * @typedef {Object} TokenUsage
   * @property {number} completionTokens - Number of tokens used for completion.
   * @property {number} promptTokens - Number of tokens used for prompts.
   */

  /**
   * @type {Game|null} The currently active game.
   */
  const activeGame = ref(null);
  const isUserInGame = ref(false);
  const isUserInLearningPath = ref(false);
  /** @type {{ gymsCount: number; learningPathsCount: number; microlearningsCount: number; }} */
  const organizationModules = reactive({
    gymsCount: 0,
    learningPathsCount: 0,
    microlearningsCount: 0,
    challanges: 0,
  });
  const gettingOrganization = ref(false);
  const paymentModalOpen = ref(false);
  const isPaymentModalInGame = ref(false);
  const paymentCreditAmount = ref(0);

  const isUserInAdmin = (
    window.location.pathname.includes(`/${appSpecificSubdomains.admin}`) && !window.location.pathname.includes(RouteNames.AdminDashboard)
  ) || window.location.hostname.includes(`${appSpecificSubdomains.admin}.`);

  // COMPUTED
  const hasOrganizationLearningPaths = computed(() => Boolean(organizationModules.learningPathsCount));
  const hasOrganizationGyms = computed(() => Boolean(organizationModules.gymsCount));
  const hasOrganizationMicrolearnings = computed(() => Boolean(organizationModules.microlearningsCount));
  const hasOrganizationChallanges = computed(() => Boolean(organizationModules.challanges));

  // METHODS
  const toggleMobileMenu = () => {
    mobileMenuOpen.value = !mobileMenuOpen.value;
  };

  const closeMobileMenu = () => {
    mobileMenuOpen.value = false;
  };

  const toggleOnboarding = () => {
    showOnboarding.value = !showOnboarding.value;
  };

  const setOrganizationId = handleErrorTracking(
    async (id) => {
      if (id) organizationId.value = id;

      if (!organizationId.value) {
        try {
          if (route.params.organizationId) organizationId.value = route.params.organizationId;
          else {
            const organizationNameId = route.params.organizationId || getSubDomain();

            organizationId.value = (await get(child(dbReference, `nameIdsToOrganizationMap/${organizationNameId}`))).val();
          }
        } catch (error) {
          console.error(error);
        }
      }

      await userStoreInstance.setUserHierarchyLevel(organizationId.value);

      if (organizationId.value === testOrganizationId) isUserOnTestOrganization.value = true;
    },
    {
      feature: errorFeatureIds.generalAppState.methods.setOrganizationId,
    },
  );

  const openPaymentModal = (creditAmount, isInsideGame = false) => {
    paymentCreditAmount.value = creditAmount;
    paymentModalOpen.value = true;
    isPaymentModalInGame.value = isInsideGame;
  };

  const closePaymentModal = () => {
    paymentCreditAmount.value = 0;
    paymentModalOpen.value = false;
    isPaymentModalInGame.value = false;
  };

  const setUserInGame = (value) => {
    isUserInGame.value = value;
  };

  const getOrganizationId = async () => {
    if (!organizationId.value) await setOrganizationId();

    return organizationId.value;
  };

  const getGroupId = () => {
    if (groupId.value === unknownEntityId) groupId.value = null;
    if (route.params.groupId) groupId.value = route.params.groupId;

    return groupId.value;
  };

  const loadGame = handleErrorTracking(
    async (gameId) => {
      let loadedGame = null;

      if (gameId) loadedGame = (await get(child(dbReference, `${gamesRoute}/${gameId}`))).val() || null;

      activeGame.value = loadedGame;

      return loadedGame || {};
    },
    {
      feature: errorFeatureIds.generalAppState.methods.loadGame,
      measurePerformance: true,
      notification: {
        title: "others.errors.messages.gameLoadIssue",
      },
    },
  );

  const clearActiveGame = () => {
    activeGame.value = null;
  };

  const updateUserInLearningPath = (value) => {
    isUserInLearningPath.value = value;
  };

  const isUserCurrentlyInLearningPath = () => isUserInLearningPath.value;

  // OrganizationModules Cache cleanup interval
  const startCacheCleanup = () => {
    setInterval(() => {
      const now = Date.now();
      Array.from(organizationModuleCache.entries()).forEach(([key, value]) => {
        if (now > value.timestamp + CACHE_DURATION) {
          organizationModuleCache.delete(key);
        }
      });
    }, CACHE_DURATION);
  };

  const generateCacheKey = (orgId, grpId) => `${orgId}:${grpId || "no-group"}`;

  const getOrganizationModules = handleErrorTracking(
    async (orgId, grpId) => {
      gettingOrganization.value = true;
      const finalGroupId = grpId === unknownEntityId ? null : grpId;

      if (!organizationId.value && !orgId) await setOrganizationId();
      if (!groupId.value && !finalGroupId) groupId.value = getGroupId();

      const finalOrgId = orgId || organizationId.value;
      const finalGrpId = finalGroupId || groupId.value;
      const cacheKey = generateCacheKey(finalOrgId, finalGrpId);

      // Check cache first
      const cachedData = organizationModuleCache.get(cacheKey);
      if (cachedData && Date.now() < cachedData.timestamp + CACHE_DURATION) {
        organizationModules.gymsCount = cachedData.data.gymsCount;
        organizationModules.microlearningsCount = cachedData.data.microlearningsCount;
        organizationModules.learningPathsCount = cachedData.data.learningPathsCount;
        organizationModules.challanges = cachedData.data.challanges;
        return cachedData.data;
      }

      // If not in cache or expired, fetch new data
      const moduleState = await getOrganizationModulesState(finalOrgId, finalGrpId);

      // Update the store
      organizationModules.gymsCount = moduleState.gymsCount;
      organizationModules.microlearningsCount = moduleState.microlearningsCount;
      organizationModules.learningPathsCount = moduleState.learningPathsCount;
      organizationModules.challanges = moduleState.challanges;

      // Cache the results
      organizationModuleCache.set(cacheKey, {
        timestamp: Date.now(),
        data: moduleState,
      });

      startCacheCleanup();

      return moduleState;
    },
    {
      feature: errorFeatureIds.generalAppState.methods.getOrganizationModules,
      measurePerformance: true,
      notification: {
        title: "others.errors.messages.organizationModulesIssue",
      },
      finalFn() {
        gettingOrganization.value = false;
      },
    },
  );

  const clearOrganizationModuleCache = (orgId, grpId) => {
    if (orgId && grpId) {
      // Clear specific cache entry
      organizationModuleCache.delete(generateCacheKey(orgId, grpId));
    } else {
      // Clear entire cache
      organizationModuleCache.clear();
    }
  };

  const setGroupId = (id) => {
    groupId.value = id;
  };

  const getUserGroupsPerOrganization = handleErrorTracking(
    async (groupIds) => {
      loading.value = true;
      let groups = [];

      groups = await Promise.all(groupIds.map(async (currentGroupId) => {
        const group = (await get(child(dbReference, `groups/${currentGroupId}/public`))).val();

        return group;
      }));

      return groups;
    },
    {
      feature: errorFeatureIds.generalAppState.methods.getUserGroupsPerOrganization,
      measurePerformance: true,
      finalFn() {
        loading.value = false;
      },
    },
  );

  const updateLoader = (value) => {
    loading.value = value;
  };

  const isPersonalOrganization = () => personalAppOrganizationId === organizationId.value;
  const isTestOrganization = () => isUserOnTestOrganization.value;

  return {
    isTabletSize,
    mobileMenuOpen,
    showOnboarding,
    organizationId,
    activeGame,
    isUserInGame,
    isUserInLearningPath,
    isUserInAdmin,
    gettingOrganization,
    hasOrganizationLearningPaths,
    hasOrganizationGyms,
    hasOrganizationMicrolearnings,
    hasOrganizationChallanges,
    paymentModalOpen,
    isPaymentModalInGame,
    paymentCreditAmount,
    isUserOnTestOrganization,
    loading,
    groupId,
    updateLoader,
    getUserGroupsPerOrganization,
    closePaymentModal,
    toggleOnboarding,
    toggleMobileMenu,
    closeMobileMenu,
    setOrganizationId,
    getOrganizationId,
    setGroupId,
    isPersonalOrganization,
    loadGame,
    setUserInGame,
    isUserCurrentlyInLearningPath,
    updateUserInLearningPath,
    clearActiveGame,
    getOrganizationModules,
    clearOrganizationModuleCache,
    openPaymentModal,
    getGroupId,
    isTestOrganization,
    organizationModules,
  };
});
