import { createRouter, createWebHistory } from "vue-router";
import { storeToRefs } from "pinia";
import userStore from "../stores/user";
import { i18n } from "../i18n";
import generalAppStateStore from "../stores/generalAppState";
import notificationsStore, { NotificationTypes } from "../stores/notifications";
import {
  passwordChangeRoutes,
  defaultRoutes,
  isGymRoute,
  isMicrolearningRoute,
  isLearningPathRoute,
  isChallangeRoute,
  RouteNames,
} from "./routes";
import isUserInGlobalApp from "../constants/isUserInGlobalApp";
import canUserAccessPage, { checkIfGeneralAppRoute } from "../methods/firebase/canUserAccessPage";
import { unknownEntityId } from "../constants/general";

const isPasswordChangeRoute = window.location.host.includes("changepassword");

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: isPasswordChangeRoute ? passwordChangeRoutes : defaultRoutes,
});

const stopLoader = (generalAppStateStoreInstance) => {
  setTimeout(() => {
    generalAppStateStoreInstance.updateLoader(false);
  }, 300);
};

router.beforeEach(async (to, from) => {
  const toObject = to;
  const userStoreInstance = userStore();
  const notificationsStoreInstance = notificationsStore();
  const userLoggedIn = await userStoreInstance.checkLogInStatus();

  if (toObject.query.notificationCode) {
    notificationsStoreInstance.addNotification({
      title: i18n.global.t(toObject.query.notificationCode),
      type: NotificationTypes[toObject.query.notificationType || "Info"],
    });

    toObject.query.notificationCode = null;
    toObject.query.notificationType = null;
  }

  if (checkIfGeneralAppRoute(toObject) && (
    toObject.meta.requiresAuth ? userLoggedIn : true
  )) return true;

  // AUTH PROTECTION
  if (toObject.matched.some((record) => record.meta.requiresAuth)) {
    // When user changes route, scroll to top of page
    if (document.getElementById("middle-column")) document.getElementById("middle-column").scrollTop = 0;

    if (!userLoggedIn) {
      return {
        name: RouteNames.Login,
        query: { redirect: toObject.fullPath },
        replace: true,
      };
    }
  } else if (
    toObject.name === RouteNames.Login
    || toObject.name === RouteNames.Registration
  ) return true;

  const generalAppStateStoreInstance = generalAppStateStore();

  generalAppStateStoreInstance.updateLoader(true);
  // PAGE ACCESS PROTECTION
  const { hasAccess, hasGroups } = await canUserAccessPage(userStoreInstance.getUserData(), toObject);

  if (!hasAccess) {
    notificationsStoreInstance.addNotification({
      title: i18n.global.t(`others.general.warnings.${hasGroups ? "noGroupsAssigned" : "notAllowed"}`),
      type: NotificationTypes.Warning,
    });

    stopLoader(generalAppStateStoreInstance);

    return from.name !== toObject.name ? from : "/";
  }

  if (toObject.params.organizationId) generalAppStateStoreInstance.setOrganizationId(toObject.params.organizationId);

  // ORGANIZATION SELECTED PROTECTION
  const organizationId = await generalAppStateStoreInstance.getOrganizationId();
  const groupId = generalAppStateStoreInstance.getGroupId();

  if (!organizationId && toObject.name !== RouteNames.OrganizationSelection && isUserInGlobalApp) {
    stopLoader(generalAppStateStoreInstance);

    return {
      name: RouteNames.OrganizationSelection,
      query: { redirect: toObject.fullPath },
      replace: true,
    };
  }

  // Check if organizationId exists and the route is not Login or OrganizationSelection and use in global app
  if (
    organizationId
    && toObject.name !== RouteNames.OrganizationSelection
    && toObject.name !== RouteNames.Login
    && !toObject.params.organizationId
    && isUserInGlobalApp
  ) {
    stopLoader(generalAppStateStoreInstance);

    return {
      name: toObject.name,
      params: { ...toObject.params, organizationId, groupId: groupId || unknownEntityId },
      query: toObject.query,
      hash: toObject.hash,
      replace: true,
    };
  }

  if (organizationId) {
    // OTHER ROUTES PROTECTION
    const {
      hasOrganizationLearningPaths, hasOrganizationGyms, hasOrganizationMicrolearnings, hasOrganizationChallanges,
    } = storeToRefs(generalAppStateStoreInstance);

    await generalAppStateStoreInstance.getOrganizationModules(organizationId, groupId);

    // Protect gym, microlearning, and learning path routes if organization has no such entities
    if (
      (isGymRoute(toObject) && !hasOrganizationGyms.value)
      || (isMicrolearningRoute(toObject) && !hasOrganizationMicrolearnings.value)
      || (isLearningPathRoute(toObject) && !hasOrganizationLearningPaths.value)
      || (isChallangeRoute(toObject) && !hasOrganizationChallanges.value)
    ) {
      // Since Learning path selection if the default page, redirect to other available page when no learning paths are available
      if (isLearningPathRoute(toObject) && !hasOrganizationLearningPaths.value) {
        if (hasOrganizationGyms.value) return { name: RouteNames.GymSelection };
        if (hasOrganizationChallanges.value) return { name: RouteNames.ProfileSelection };
        return { name: RouteNames.Microlearnings };
      }

      notificationsStoreInstance.addNotification({
        title: i18n.global.t("others.general.warnings.organizationHasNoContent"),
        type: NotificationTypes.Warning,
      });

      stopLoader(generalAppStateStoreInstance);

      return from.name !== toObject.name ? from : "/";
    }
  }

  stopLoader(generalAppStateStoreInstance);

  return true;
});

router.isReady().then(async () => {
  const generalAppStateStoreInstance = generalAppStateStore();

  generalAppStateStoreInstance.updateLoader(true);

  if (generalAppStateStoreInstance.isUserInAdmin) return;

  const userStoreInstance = userStore();
  const isUserLoggedIn = await userStoreInstance.checkLogInStatus();

  if (!isUserLoggedIn) {
    stopLoader(generalAppStateStoreInstance);
    return;
  }

  const { hasAccess } = await canUserAccessPage(userStoreInstance.getUserData(), router.currentRoute.value);

  if (isUserLoggedIn && router.currentRoute.value.query.redirect && hasAccess) {
    stopLoader(generalAppStateStoreInstance);

    router.push(router.currentRoute.value.query.redirect);
  }

  stopLoader(generalAppStateStoreInstance);
});

export default router;
