export default {
  otazník: "?",
  vykřičník: "!",
  čárka: ",",
  čarka: ",",
  tečka: ".",
  dvojtečka: ":",
  středník: ";",
  pomlčka: "-",
  uvozovky: "\"",
  "u vozovky": "\"",
  apostrof: "'",
  závorka: "(",
};
