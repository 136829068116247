import downloadFullAnalysis from "./downloadFullAnalysis";
import punctuaction from "./punctuaction";
import general from "./general";
import errors from "./errors";

export default {
  downloadFullAnalysis,
  punctuaction,
  general,
  errors,
};
