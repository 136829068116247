<script lang="ts" setup>
import { storeToRefs } from "pinia";
import {
  mdiAlertOutline, mdiCheckCircleOutline, mdiClose, mdiCloseBoxOutline, mdiInformationOutline,
} from "@mdi/js";
import notificationsStore, { NotificationTypes } from "../stores/notifications";
import Icon from "./Icon.vue";

const notificationsStoreInstance = notificationsStore();
const {
  notifications,
} = storeToRefs(notificationsStoreInstance);

// COMPUTED
const getNotificationTypeIcon = (type) => {
  switch (type) {
    case NotificationTypes.Info: return mdiInformationOutline;
    case NotificationTypes.Error: return mdiCloseBoxOutline;
    case NotificationTypes.Success: return mdiCheckCircleOutline;
    case NotificationTypes.Warning: return mdiAlertOutline;
    default: return mdiInformationOutline;
  }
};
</script>

<template>
  <Teleport to="body">
    <div
      v-if="notifications.length"
      class="fixed right-5 left-5 overflow-y-auto p-2 md:max-h-[100dvh - 2.5rem] h-auto top-5 md:top-10 md:left-auto md:w-96 max-w-[100vw - 2.5rem] z-50"
    >
      <div
        v-for="{
          id,
          title,
          message,
          type,
          timeLeft,
          permanent,
          action,
        } in notifications"
        :key="`notification-${id}`"
        class="group transition-opacity duration-1000 min-h-[1rem] p-4 rounded-lg mb-2 last:mb-0 relative bg-gradient-to-tr text-white cursor-pointer"
        :class="{
          'from-[#E562BF]/80 to-[#8E8BFF]': type === NotificationTypes.Info,
          'from-[#EBB93A] to-[#EBB93A]': type === NotificationTypes.Warning,
          'from-[#E54750] to-[#F87878]': type === NotificationTypes.Error,
          'from-[#4CBE65] to-[#77D756]': type === NotificationTypes.Success,
          'opacity-0': timeLeft <= 1000 && !permanent,
          'opacity-100': timeLeft > 1000 || permanent,
        }"
        @click="!permanent ? notificationsStoreInstance.removeNotification(id) : () => {}"
      >
        <div class="group-hover:block hidden absolute top-3 right-3 text-white text-sm">
          <Icon :path="mdiClose" />
        </div>

        <div class="notification-item items-center">
          <div class="w-10 h-10 min-w-10 rounded-full bg-darkPurple/20 text-lg flex items-center justify-center">
            <Icon :path="getNotificationTypeIcon(type)" />
          </div>

          <div class="flex flex-col gap-1">
            <div class="flex justify-between items-start">
              <h3 class="text-xs font-bold break-words overflow-x-hidden overflow-auto">
                {{ title }}
              </h3>
            </div>

            <p v-if="message" class="text-[0.7rem] break-words overflow-auto">
              {{ message }}
            </p>

            <button
              v-if="action"
              type="button"
              class="px-3 py-2 text-xs bg-darkPurple text-white rounded-lg w-fit mt-2 mx-auto hover:bg-darkPurple/80 transition-colors duration-200"
              @click.stop="action.click"
            >
              {{ action.title }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.notification-item {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
}
</style>
