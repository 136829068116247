export default {
  title: "Nastavení hesla",
  resetTitle: "Resetování hesla",
  password: "Nové heslo",
  passwordAgain: "Nové heslo znovu",
  submit: "Nastavit heslo",
  reset: "Resetovat heslo",
  processing: "Heslo se nastavuje...",
  email: "E-mail",
  oldPassword: "Staré heslo",
  alertMessages: {
    passwordsDontMatch: "Hesla se neshodují",
    minSixCharacters: "Heslo musí mít alespoň 6 znaků",
    invalidPassowordChangeLink: "Neplatný odkaz na změnu/nastavení hesla",
    oldPasswordRequired: "Staré heslo je povinné",
    emailRequired: "E-mail je povinný",
  },
  messages: {
    success: "Heslo bylo změněno",
    successMessage: "Nyní se můžete přihlásit s novým heslem!",
    login: "Zpět na přihlášení",
    resetSuccess: "Heslo bylo resetováno",
    resetSuccessMessage: "Byl vám odeslán email s odkazem na nastavení hesla. Může se stát, že se email dostane do spamu.",
  },
};
