export default {
  unknown: "unknown",
  auth: {
    login: "auth/login",
    logout: "auth/logout",
    register: "auth/register",
    resetPassword: "auth/resetPassword",
    changePassword: "auth/changePassword",
    verifyEmail: "auth/verifyEmail",
    updateProfile: "auth/updateProfile",
    deleteAccount: "auth/deleteAccount",

    methods: {
      logInUser: "auth/logInUser",
      logOutUser: "auth/logOutUser",
      register: "auth/register",
      checkLogInStatus: "auth/checkLogInStatus",
      changePassword: "auth/changePassword",
      resetPassword: "auth/resetPassword",
      updatePersonalInfo: "auth/updatePersonalInfo",
      setUserHierarchyLevel: "auth/setUserHierarchyLevel",
    },
  },
  generalAppState: {
    methods: {
      setOrganizationId: "generalAppState/setOrganizationId",
      getOrganizationId: "generalAppState/getOrganizationId",
      loadGame: "generalAppState/loadGame",
      getOrganizationModules: "generalAppState/getOrganizationModules",
      getUserGroupsPerOrganization: "generalAppState/getUserGroupsPerOrganization",
    },
  },
  entityManagement: {
    methods: {
      fetchOrganization: "entityManagement/fetchOrganization",
      fetchMicrolearnings: "entityManagement/fetchMicrolearnings",
      fetchMicrolearningsForOrganization: "entityManagement/fetchMicrolearningsForOrganization",
      fetchAiProfilesForOrganization: "entityManagement/fetchAiProfilesForOrganization",
      fetchGymsForOrganization: "entityManagement/fetchGymsForOrganization",
      fetchPathsForOrganization: "entityManagement/fetchPathsForOrganization",
      fetchGroupsForOrganization: "entityManagement/fetchGroupsForOrganization",
      fetchMembersForOrganization: "entityManagement/fetchMembersForOrganization",
      getUserByEmail: "entityManagement/getUserByEmail",
      addCreditsToMembers: "entityManagement/addCreditsToMembers",
      createOrUpdateOrganization: "entityManagement/createOrUpdateOrganization",
      updateGroupsData: "entityManagement/updateGroupsData",
      createUser: "entityManagement/createUser",
      removeMemberFromOrganization: "entityManagement/removeMemberFromOrganization",
      removeGroupFromOrganization: "entityManagement/removeGroupFromOrganization",
    },
  },
  gym: {
    methods: {
      fetchSpecificGym: "gym/fetchSpecificGym",
      fetchGyms: "gym/fetchGyms",
      getStreamedResponses: "gym/getStreamedResponses",
      fetchGymExercises: "gym/fetchGymExercises",
    },
  },
  challange: {
    methods: {
      fetchProfiles: "challange/fetchProfiles",
      getStreamedResponses: "challange/getStreamedResponses",
    },
  },
  learningPath: {
    methods: {
      fetchSpecificPath: "learningPath/fetchSpecificPath",
      fetchPaths: "learningPath/fetchPaths",
      handleCreditsCheck: "learningPath/handleCreditsCheck",
      startJourney: "learningPath/startJourney",
      loadJourney: "learningPath/loadJourney",
      handleStepGameStart: "learningPath/handleStepGameStart",
      handleJourneyEnd: "learningPath/handleJourneyEnd",
    },
  },
  others: {
    getAudioFromText: "others/getAudioFromText",
    generateStreamedAnalysis: "others/generateStreamedAnalysis",
    fetchJourneyCompletionInfo: "others/fetchJourneyCompletionInfo",
    generateStreamedJourneyAnalysis: "others/generateStreamedJourneyAnalysis",
  },
};
