import { getAuth, signOut } from "firebase/auth";
import eventBus, { eventTypes } from "../../constants/eventBus";
import { handleErrorTracking } from "../../middleWare/errorTracker";
import errorFeatureIds from "../../constants/errorFeatureIds";

export default handleErrorTracking(
  async () => {
    const auth = getAuth();

    window.sessionStorage.setItem("token", "");

    await signOut(auth);
    eventBus.emit(eventTypes.userLoggedOut);
  },
  {
    feature: errorFeatureIds.auth.methods.logOutUser,
    measurePerformance: true,
    notification: {
      title: "others.errors.messages.logoutIssue",
    },
  },
);
