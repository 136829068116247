import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// Required for side-effects
import "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: `${import.meta.env.VITE_FIREBASE_API_KEY}`,
  authDomain: `${import.meta.env.VITE_FIREBASE_AUTH_DOMAIN}`,
  databaseURL: `${import.meta.env.VITE_FIREBASE_DATABASE_URL}`,
  projectId: `${import.meta.env.VITE_FIREBASE_PROJECT_ID}`,
  storageBucket: `${import.meta.env.VITE_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${import.meta.env.VITE_FIREBASE_APP_ID}`,
  measurementId: `${import.meta.env.VITE_FIREBASE_MEASUREMENT_ID}`,
};

export default () => {
  // Initialize Firebase
  initializeApp(firebaseConfig);

  // Set Login persistence and change watcher
  const auth = getAuth();

  window.firebaseInitialized = true;

  onAuthStateChanged(auth, async (user) => {
    const userStore = await import("./stores/user");
    const userStoreInstance = userStore.default();

    auth?.currentUser?.getIdToken(true).then((token) => {
      window.sessionStorage.setItem("token", token);
    });

    if (user) await userStoreInstance.setUserData(user);
  });
};
