export default {
  chooseGame: "Výběr hry",
  chooseGym: "Výběr posilovny",
  chooseMicrolearning: "Výběr microlearningu",
  learningPaths: "Lekce",
  logOut: "Odhlásit se",
  support: "Podpora",
  termsOfUse: "Podmínky použití",
  remainingCreditPercentage: "zbývajících kreditů",
  switchOrganization: "Změnit organizaci",
  profile: "Profil",
  history: "Historie",
  adminDashboard: "Administrátorský panel",
};
