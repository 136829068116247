export default {
  title: "Password setup",
  resetTitle: "Password reset",
  password: "New password",
  passwordAgain: "New password again",
  submit: "Set password",
  reset: "Reset password",
  processing: "Password is being setup...",
  email: "E-mail",
  oldPassword: "Old password",
  alertMessages: {
    passwordsDontMatch: "Passwords don't match.",
    minSixCharacters: "The password has to be at least 6 characters long.",
    invalidPassowordChangeLink: "The password setup/change link is invalid.",
    oldPasswordRequired: "Old password is required.",
    emailRequired: "E-mail is required.",
  },
  messages: {
    success: "Password has been changed.",
    successMessage: "You can now log in with your new password!",
    login: "Back to login",
    resetSuccess: "Password has been reset.",
    resetSuccessMessage: "An email with a link to set your password has been sent to you. It might end up in your spam folder.",
  },
};
