export default {
  titles: {
    success: "Výborně! Jsi na konci rozhovoru.",
    fail: "Pojď to zkusit ještě jednou.",
  },
  messages: {
    success: "Pojďme si konverzaci vyhodnotit.",
    fail: "Teď to neklaplo, ale nevadí. Vyzkoušej si tuto situaci ještě jednou.",
    failToAnalysis: "Teď to neklaplo, ale nevadí. Nech si cvičení vyhodnotit a mrkni co jsi mohl/a udělat lépe.",
    analysisRequirement: "Musíte odeslat minimálně 2 zprávy, abyste mohli vyhodnotit cvičení.",
    limitedTimeOffer: "Časově omezená nabídka!",
    moneyBackGuarantee: "Záruka vrácení peněz!",
  },
  buttons: {
    repeatGame: "Opakovat cvičení",
    analyzeGame: "Vyhodnotit cvičení",
    newGame: "Nová konverzace",
    unlockLesson: "Koupit plnou lekci",
  },
};
