export default {
  buttons: {
    play: "Hrát hru",
    detail: "Detail",
  },
  meetingTypes: {
    video: "Video schůzka",
    inPerson: "Osobní schůzka",
    call: "Telefonická schůzka",
  },
  difficultyTypes: {
    true: "Náročné",
  },
  descriptionItems: {
    oponentDescription: "Tvůj soupeř",
    situationDescription: "Popis situace",
    goalDescription: "Tvůj cíl",
  },
  trainedCompetences: "Trénované kompetence",
};
