export default {
  title: "Log in",
  email: "E-mail",
  password: "Password",
  logginIn: "Logging in...",
  registering: "Registering...",
  passwordAgain: "Password again",
  name: "Name",
  surname: "Surname",
  registrationTitle: "Registration",
  passwordMessage: "Password must contain at least 8 characters and contain at least one number.",
  messages: {
    verificationEmail: "We have sent you an e-mail with a verification link. Please verify your e-mail address to enable log in.",
    registrationFailed: "Registration failed",
    redirectNotWorking: "You should be redirected to the app now. If not, click on this link:",
    registrationSuccess: "Registration successful! We have added some credits to you account to get you started, have fun!",
    emailVerified: "Your e-mail has been successfully verified, you can now log in.",
  },
  buttons: {
    logIn: "Log in",
    forgotPassword: "Forgot password",
    changePassword: "Change password",
    google: "Log in with Google",
    facebook: "Log in with Facebook",
    apple: "Log in with Apple",
    register: "Register",
    redirect: "Redirect",
  },
  validationMessages: {
    email: "Invalid e-mail",
    emailRequired: "E-mail is required",
    password: "Password is required",
    passwordAgain: "Passwords do not match",
    nameRequired: "Name is required",
    surnameRequired: "Surname is required",
    reCaptcha: "Confirm that you are not a robot",
  },
};
