export default {
  warnings: {
    canNotAccessMicrophone: "Nemůžeme získat přístup k mikrofonu. Zkontrolujte, zda máte povolený přístup k mikrofonu a zkuste to znovu.",
    webspeechNotSupported: "Možnost diktování hlasem (Web Speech API) není umožněno v tomto prohlížeči.",
    reEnableMicrophone: "Povolte prosím svůj mikrofon v prohlížeči.",
    notSupportedOnThisBrowser: "Tato funkce není podporována ve vašem prohlížeči.",
    notAllowed: "Nemáte povolení pro přístup na tuto stránku.",
    noGroupsAssigned: "Nejste přiřazeni do žádné skupiny. Kontaktujte správce organizace.",
    organizationHasNoContent: "Organizace/Skupina nemá žádný obsah. Prosím, kontaktujte správce organizace.",
    speechAi: "Hlas, který uslyšíte, je vygenerovaný umělou inteligencí.",
  },
  buttons: {
    back: "Zpět",
    analyze: "Vyhodnotit",
    showAnalysis: "Zobrazit mé hodnocení",
    support: "Podpora",
    close: "Zavřít",
  },
  termsLink: "https://www.provocator.ai/terms-of-use",
  gameStatuses: {
    completed: "Dokončeno",
    in_progress: "Započato",
    not_started: "Nezačato",
  },
  gameTypes: {
    gymShort: "Krátké cvičení",
    challange: "Modelová situace",
    gymLong: "Dlouhé cvičení",
  },
  others: {
    selected: "vybrán",
  },
};
