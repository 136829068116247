export default {
  titles: {
    main: "Historie her a lekcí",
    games: "Hry",
    lessons: "Lekce",
  },
  fields: {
    state: "Stav",
    dateOfStart: "Datum zahájení",
    lastMessage: "Poslední zpráva",
    reachedStep: "Dosažený krok",
    numberOfMessages: "Počet zpráv",
  },
  statuses: {
    in_progress: "Probíhá",
    completed: "Dokončeno",
  },
  buttons: {
    loadMore: "Načíst další",
  },
  moduleTypes: {
    gymShort: "Malý Gym",
    gymLong: "Velký Gym",
    challange: "Výzva",
  },
};
