export default {
  title: "Přihlášení",
  email: "E-mail",
  password: "Heslo",
  logginIn: "Přihlašuji...",
  registering: "Registruji...",
  passwordAgain: "Heslo znovu",
  name: "Jméno",
  surname: "Příjmení",
  registrationTitle: "Registrace",
  passwordMessage: "Heslo musí obsahovat alespoň 8 znaků a alespoň jedno číslo.",
  messages: {
    verificationEmail: "Na váš e-mail jsme zaslali odkaz na ověření e-mailové adresy. Ověřte ji prosím, abyste se mohli přihlásit.",
    registrationFailed: "Registrace se nezdařila",
    redirectNotWorking: "Nyní byste měli být přesměrováni přímo do aplikace. Pokud se tak nestane, klikněte na tento odkaz:",
    registrationSuccess: "Registrace proběhla úspěšně! Přidali jsme vám na začátek nějaké kredity, přejeme pěknou zábavu.",
    emailVerified: "Váš e-mail byl úspěšně ověřen, nyní se můžete přihlásit.",
  },
  buttons: {
    logIn: "Přihlásit se",
    forgotPassword: "Zapomenuté heslo",
    changePassword: "Změnit heslo",
    google: "Přihlásit se přes Google",
    facebook: "Přihlásit se přes Facebook",
    apple: "Přihlásit se přes Apple",
    register: "Registrovat se",
    redirect: "Přesměrovat",
  },
  validationMessages: {
    email: "Neplatný e-mail",
    emailRequired: "E-mail je povinný",
    password: "Neplatné heslo",
    passwordAgain: "Hesla se neshodují",
    nameRequired: "Jméno je povinné",
    surnameRequired: "Příjmení je povinné",
    reCaptcha: "Potvrďte, že nejste robot",
  },
};
