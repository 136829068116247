export default {
  warnings: {
    canNotAccessMicrophone: "We can't access your microphone. Please check if you have microphone access enabled and try again.",
    webspeechNotSupported: "Speech dictation (Web Speech API) is not enabled in this browser.",
    reEnableMicrophone: "Please enable your microphone in browser.",
    notSupportedOnThisBrowser: "This feature is not supported on this browser.",
    notAllowed: "You are not allowed to access this page.",
    noGroupsAssigned: "You are not assigned to any group. Please contact your organization admin.",
    organizationHasNoContent: "Organization/Group has no content. Please contact your organization admin.",
    speechAi: "The voice that you will hear is an AI generated voice.",
  },
  buttons: {
    back: "Back",
    analyze: "Analyze",
    showAnalysis: "Show My Analysis",
    support: "Support",
    close: "Close",
  },
  termsLink: "https://www.provocator.ai/en/terms-of-use",
  gameStatuses: {
    completed: "Completed",
    in_progress: "Started",
    not_started: "Not Started",
  },
  gameTypes: {
    gymShort: "Short Gym",
    challange: "Model Situation",
    gymLong: "Long Gym",
  },
  others: {
    selected: "selected",
  },
};
