export default {
  challange: "challange",
  generalCoach: "generalCoach",
  analysisCoach: "analysisCoach",
  gym: "gym",
  gymShort: "gymShort",
  gymLong: "gymLong",
  challangeCoach: "coach",
  chat: "chat",
  microlearning: "microlearning",
};
