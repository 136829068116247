export default {
  about: "O lekci",
  content: "Obsah lekce",
  steps: "Počet kroků",
  credits: "Kreditů",
  free: "Zdarma",
  purchased: "Zakoupeno",
  buttons: {
    buyAndPlay: "Koupit a začít",
    continue: "Pokračovat",
    begin: "Začít lekci",
    repeat: "Opakovat lekci",
  },
};
