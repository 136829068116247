export default {
  titles: {
    main: "Game and Lesson History",
    games: "Games",
    lessons: "Lessons",
  },
  fields: {
    state: "State",
    dateOfStart: "Date of start",
    lastMessage: "Last message",
    reachedStep: "Reached step",
    numberOfMessages: "Number of messages",
  },
  statuses: {
    in_progress: "In progress",
    completed: "Completed",
  },
  buttons: {
    loadMore: "Load more",
  },
  moduleTypes: {
    gymShort: "Small Gym",
    gymLong: "Big Gym",
    challange: "Challenge",
  },
};
