export default {
  title: "Váš profil",
  firstName: "Jméno",
  lastName: "Příjmení",
  changeInfo: "Změnit informace",
  credits: "Kredity",
  creditsAmount: "Moje kredity",
  minNumberOfCredits: "Minimální počet kreditů je {minNumberOfCredits}",
  buttons: {
    buyCredits: "Dokoupit kredity",
    update: "Změnit",
  },
  labels: {
    firstName: "Jméno",
    lastName: "Příjmení",
    addCredits: "Přidat kredity",
  },
  placeholders: {
    addCredits: "Množství kreditů k přidání, minimální počet je {minNumberOfCredits}",
  },
  messages: {
    creditsAdded: "Kredity byly úspěšně přidány.",
    creditsNotAdded: "Kredity nebylo možné přidat.",
    noChanges: "Nebyly provedeny žádné změny.",
    updateSuccess: "Profil byl úspěšně aktualizován.",
  },
};
