import correctEndpointUrl from "../../constants/firebaseEndpoint";

export default async ({
  userId,
  organizationId,
}) => {
  const response = await fetch(`${correctEndpointUrl}/getUserGroupsPerOrganization`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      userId,
      organizationId,
    }),
  });

  return response?.json();
};
