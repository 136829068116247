export default {
  title: "Admin Dashboard",

  titles: {
    financialOverview: "Credit Overview",
    members: "Members",
    addMember: "Add Member",
    bulkUpload: "Adding Members",
    groups: "Groups",
  },
  table: {
    headers: {
      name: "Name",
      groupName: "Group Name",
      groupDescription: "Group Description",
      members: "Members",
      learningPaths: "Lessons",
      aiProfiles: "Model Situations",
      email: "Email",
      role: "Role",
      limit: "Credit Limit for Distribution",
      actions: "Actions",
      creditsPerOrganization: "Remaining Credits",
      usedCreditsPerOrganization: "Used Credits",
      memberSelectPlaceholder: "Members Select",
      searchPlaceholder: "Search...",
      learningPathsPlaceholder: "Lessons Select",
      aiProfilesPlaceholder: "Model Situations Select",
    },
    actions: {
      edit: "Edit",
      delete: "Delete",
      save: "Save Changes",
      saveGroupChanges: "Save Group Changes",
      add: "Add",
      close: "Close",
      bulkUpload: "Upload via CSV",
      upload: "Upload",
    },
  },

  inputs: {
    newMember: {
      email: "Email",
      firstName: "Name",
      lastName: "Last Name",
      title: "Title",
      subTitle: "Sub Title",
      phone: "Phone",
      memberDescription: "Description",
      dateOfBirth: "Date of Birth",
      position: "Position",
      memberImage: "Image",
    },
  },

  messages: {
    deleteMember: "Are you sure you want to delete this member {email}?",
    memberAddSuccess: "Member was successfully added.",
    memberAddFail: "Member could not be added.",
    memberEditSuccess: "Member was successfully edited.",
    memberEditFail: "Member could not be edited.",
    memberDeleteSuccess: "Member was successfully removed.",
    memberDeleteFail: "Member could not be removed.",
    changesSaved: "Changes were successfully saved.",
    changesNotSaved: "Changes could not be saved.",
    bulkUploadSuccess: "Members were successfully added.",
    unsaveChanges: "You have unsaved changes. Are you sure you want to leave?",
    missingMemberData: "Some fields are not filled in. Please fill in all required fields.",
    correctCsvFormat: "The CSV file must be in the following format and it must have only 3 columns with these names: firstName | lastName | email",
    wrongCsvFormat: "CSV file is not in the correct format, it must have only 3 columns with these names: firstName | lastName | email",
    notEnoughCredits: "You do not have enough credits for this action.",
    invalidCredits: "Invalid credits value.",
    deleteGroup: "Are you sure you want to delete this group {name}?",
    groupDeleteSuccess: "Group was successfully removed.",
    groupDeleteFail: "Group could not be removed.",
  },
};
