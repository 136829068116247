import isUserInGlobalApp from "../constants/isUserInGlobalApp";

/**
 * Route names enum. All route names are stored here to prevent duplicities.
 * @type {{ [key: string]: string }}
 */
export const RouteNames = {
  ProfileSelection: "profileSelection",
  Chat: "chat",
  Analysis: "analysis",
  Gyms: "gyms",
  GymSelection: "gymSelection",
  Gym: "gym",
  GymAnalysis: "gymAnalysis",
  Microlearnings: "microlearnings",
  Microlearning: "microlearning",
  LearningPaths: "learningPaths",
  LearningPath: "learningPath",
  LearningPathDetail: "learningPathDetail",
  JourneyAnalysis: "journeyAnalysis",
  Game: "game",
  History: "history",
  Coach: "coach",
  HowTo: "howTo",
  Admin: "admin",
  Client: "client",
  Dashboard: "dashboard,",
  Login: "login",
  Registration: "registration",
  SimpleLogin: "simpleLogin",
  ChangePassword: "changePassword",
  OrganizationSelection: "organizationSelection",
  EmailVerification: "emailVerified",
  Profile: "profile",
  AdminDashboard: "adminDashboard",
};

const gameRoutes = [
  {
    path: "profileSelection/:profileId?",
    alias: "",
    name: RouteNames.ProfileSelection,
    component: () => import("../views/dashboard/challange/ProfileSelection.vue"),
  },
  {
    path: "chat/:profileId?/:gameId?",
    name: RouteNames.Chat,
    component: () => import("../views/dashboard/challange/Chat.vue"),
  },
  {
    path: "analysis/:gameId?",
    name: RouteNames.Analysis,
    component: () => import("../views/dashboard/challange/Analysis.vue"),
  },
];

const gymRoutes = [
  {
    path: "gymSelection",
    name: RouteNames.GymSelection,
    component: () => import("../views/dashboard/gyms/GymSelection.vue"),
  },
  {
    path: "gym/:gymId/:gameId?",
    name: RouteNames.Gym,
    component: () => import("../views/dashboard/gyms/Chat.vue"),
  },
  {
    path: "gymAnalysis/:gameId?",
    name: RouteNames.GymAnalysis,
    component: () => import("../views/dashboard/gyms/Analysis.vue"),
  },
];

const microlearningRoutes = [
  {
    path: "",
    name: RouteNames.Microlearnings,
    component: () => import("../views/dashboard/microlearnings/MicrolearningSelection.vue"),
  },
  {
    path: ":id",
    name: RouteNames.Microlearning,
    component: () => import("../views/dashboard/microlearnings/Microlearning.vue"),
    props: true,
  },
];

const learningPathRoutes = [
  {
    path: "",
    name: RouteNames.LearningPaths,
    component: () => import("../views/dashboard/learningPaths/LearningPathSelection.vue"),
  },
  {
    path: "detail/:learningPathId",
    name: RouteNames.LearningPathDetail,
    component: () => import("../views/dashboard/learningPaths/LearningPathDetail.vue"),
    props: true,
  },
  {
    path: ":id/:journeyId/:stepIndex?",
    name: RouteNames.LearningPath,
    component: () => import("../views/dashboard/learningPaths/LearningPath.vue"),
    props: true,
  },
  {
    path: "journeyAnalysis/:journeyId",
    name: RouteNames.JourneyAnalysis,
    component: () => import("../views/dashboard/learningPaths/JourneyAnalysis.vue"),
    props: true,
  },
];

const dashboardRoutes = [
  // {
  //   path: "",
  //   name: "home",
  //   component: () => import("../views/dashboard/Home.vue") // TODO: change back
  // },
  {
    path: `${isUserInGlobalApp ? ":organizationId?/:groupId?/" : ""}game`,
    name: RouteNames.Game,
    children: gameRoutes,
  },
  {
    path: `${isUserInGlobalApp ? ":organizationId?/:groupId?/" : ""}gyms`,
    name: RouteNames.Gyms,
    children: gymRoutes,
  },
  {
    path: `${isUserInGlobalApp ? ":organizationId?/:groupId?/" : ""}microlearnings`,
    children: microlearningRoutes,
  },
  {
    path: `${isUserInGlobalApp ? ":organizationId?/:groupId?/" : ""}learningPaths`,
    alias: isUserInGlobalApp ? ":organizationId?/:groupId?/" : "",
    children: learningPathRoutes,
  },
  {
    path: `${isUserInGlobalApp ? ":organizationId?/:groupId?/" : ""}profile`,
    name: RouteNames.Profile,
    component: () => import("../views/dashboard/Profile.vue"),
  },
  {
    path: `${isUserInGlobalApp ? ":organizationId?/:groupId?/" : ""}history`,
    name: RouteNames.History,
    component: () => import("../views/dashboard/History.vue"),
  },
  {
    path: `${isUserInGlobalApp ? ":organizationId?/:groupId?/" : ""}adminDashboard`,
    name: RouteNames.AdminDashboard,
    component: () => import("../views/dashboard/AdminDashboard.vue"),
  },
  // {
  //   path: "coach",
  //   name: RouteNames.Coach,
  //   component: () => import("../views/dashboard/History.vue"),
  // },
  // {
  //   path: "howTo",
  //   name: RouteNames.HowTo,
  //   component: () => import("../views/dashboard/History.vue"),
  // },
];

export const defaultRoutes = [
  {
    path: "/admin",
    name: RouteNames.Admin,
    component: () => import("../views/Admin.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: isUserInGlobalApp ? "/:organizationId?" : "/",
    name: window.location.hostname.includes("admin.") ? RouteNames.Admin : RouteNames.Client,
    component: () => (window.location.hostname.includes("admin.") ? import("../views/Admin.vue") : import("../views/Client.vue")),
    meta: {
      requiresAuth: true,
    },
    ...(!window.location.hostname.includes("admin.") && {
      children: [
        {
          path: "/",
          name: RouteNames.Dashboard,
          children: dashboardRoutes,
        },
      ],
    }),
  },
  {
    path: "/organizationSelection",
    name: RouteNames.OrganizationSelection,
    component: () => import("../views/OrganizationSelection.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: RouteNames.Login,
    component: () => import("../views/LogIn.vue"),
  },
  {
    path: "/registration",
    name: RouteNames.Registration,
    component: () => import("../views/Registration.vue"),
  },
  {
    path: "/emailVerified",
    name: RouteNames.EmailVerification,
    component: () => import("../views/EmailVerification.vue"),
  },
  {
    path: "/simpleLogin",
    name: RouteNames.SimpleLogin,
    component: () => import("../views/SimpleLogIn.vue"),
  },
  {
    path: "/changePassword",
    name: RouteNames.ChangePassword,
    component: () => import("../views/ChangePassword.vue"),
  },
];

export const passwordChangeRoutes = [
  {
    path: "/",
    name: RouteNames.ChangePassword,
    component: () => import("../views/ChangePassword.vue"),
  },
];

// Route groups
export const isGymRoute = (route) => route.name === RouteNames.Gyms || route.name === RouteNames.GymSelection || route.name === RouteNames.Gym || route.name === RouteNames.GymAnalysis;
export const isMicrolearningRoute = (route) => route.name === RouteNames.Microlearnings || route.name === RouteNames.Microlearnings;
export const isLearningPathRoute = (route) => route.name === RouteNames.LearningPaths || route.name === RouteNames.LearningPath;
export const isChallangeRoute = (route) => route.name === RouteNames.ProfileSelection || route.name === RouteNames.Chat;
