export default {
  title: "Administrační panel",
  titles: {
    financialOverview: "Celkový přehled kreditů",
    members: "Členové",
    addMember: "Přidat člena",
    bulkUpload: "Přidání členů",
    groups: "Skupiny",
  },
  table: {
    headers: {
      name: "Jméno",
      groupName: "Název skupiny",
      groupDescription: "Popis skupiny",
      members: "Členové",
      learningPaths: "Lekce",
      aiProfiles: "Modelové situace",
      email: "Email",
      role: "Role",
      limit: "Distribuční Limit kreditů",
      actions: "Akce",
      creditsPerOrganization: "Zbývající kredity",
      usedCreditsPerOrganization: "Použité kredity",
      memberSelectPlaceholder: "Výběr členů",
      searchPlaceholder: "Prohledat...",
      learningPathsPlaceholder: "Výběr lekcí",
      aiProfilesPlaceholder: "Výběr modelových situací",
    },
    actions: {
      edit: "Upravit",
      delete: "Smazat",
      save: "Uložit změny",
      saveGroupChanges: "Uložit změny skupin",
      add: "Přidat",
      close: "Zavřít",
      bulkUpload: "Nahrát přes CSV",
      upload: "Nahrát",
    },
  },

  inputs: {
    newMember: {
      email: "Email",
      firstName: "Jméno",
      lastName: "Příjmení",
      title: "Titul",
      subTitle: "Podtitul",
      phone: "Telefon",
      memberDescription: "Popis",
      dateOfBirth: "Datum narození",
      position: "Pozice",
      memberImage: "Obrázek",
    },
  },

  messages: {
    deleteMember: "Opravdu chcete smazat tohoto člena {email}?",
    memberAddSuccess: "Člen byl úspěšně přidán.",
    memberAddFail: "Člena se nepodařilo přidat.",
    memberEditSuccess: "Člen byl úspěšně upraven.",
    memberEditFail: "Člena se nepodařilo upravit.",
    memberDeleteSuccess: "Člen byl úspěšně odstraněn.",
    memberDeleteFail: "Člena se nepodařilo odstranit.",
    changesSaved: "Změny byly úspěšně uloženy.",
    changesNotSaved: "Změny se nepodařilo uložit.",
    bulkUploadSuccess: "Členové byli úspěšně přidáni.",
    unsaveChanges: "Máte neuložené změny. Opravdu chcete odejít?",
    missingMemberData: "Některá pole nejsou vyplněna. Prosím vyplňte všechna povinná pole.",
    correctCsvFormat: "CSV soubor musí mít následující formát a musí mít pouze 3 sloupce s těmito názvy: firstName | lastName | email",
    wrongCsvFormat: "CSV soubor nemá správný formát, musí mít pouze 3 sloupce s těmito názvy: firstName | lastName | email",
    notEnoughCredits: "Nemáte dostatek kreditů pro tuto akci.",
    invalidCredits: "Neplatná hodnota kreditů.",
    deleteGroup: "Are you sure you want to delete this group {name}?",
    groupDeleteSuccess: "Group was successfully removed.",
    groupDeleteFail: "Group could not be removed.",
  },
};
