export default {
  title: "Purchase Credits",
  input: "Number of credits to purchase",
  insuficientCreditsInGameMessage: "You don't have enough credits to play, add credits to continue playing.",
  minNumberOfCreditsToPlay: "Minimum number of credits to play this game is:",
  buttons: {
    pay: "Buy",
    cancel: "Cancel",
  },
};
