export default {
  buttons: {
    changeGame: "Změnit hru",
    analyzeConversation: "Vyhodnotit cvičení",
    showAnalysis: "Zobrazit vyhodnocení",
    assignment: "Zadání",
    close: "Zavřít",
    coach: "Poraď mi",
    otherOptions: "Další možnosti",
    copy: "Zkopírovat",
    toggleAudio: "Zap/Vyp zvuk",
    togglePlayback: "Zap/Vyp přehrávání",
  },
  warnings: {
    minNumberOfMessagesForAnalysis: "Musíte odeslat minimálně 2 zprávy, abyste mohli vyhodnotit cvičení.",
  },
  placeholders: {
    writeMessage: "Napište zprávu...",
  },
  messages: {
    messagesLeft: "Zbývá vám {messagesLeft} zpráva. | Zbývají vám {messagesLeft} zprávy. | Zbývá vám {messagesLeft} zpráv.",
    outOfMessages: "Vyčerpali jste počet zpráv, které můžete odeslat. Nechte si cvičení vyhodnotit.",
    gameCompleted: "Hra byla již ukončena.",
    termsOfUse: "Mluvíš s AI, která má své limity. O těchto limitech a o tom, co AI nepsat, se více dozvíš ZDE.",
    retriesLeft: "Zbývá vám {retriesLeft} opakování hry. | Zbývají vám {retriesLeft} opakování hry. | Zbývá vám {retriesLeft} opakování hry.",
  },
};
