import {
  getDatabase, ref as dbRef, child, get,
} from "firebase/database";
import { adminEmail, personalAppOrganizationId } from "../../constants/general";
import generalAppStartStore from "../../stores/generalAppState";
import isUserInGlobalApp from "../../constants/isUserInGlobalApp";
import getSubDomain from "../utils/getSubDomain";
import { RouteNames } from "../../router/routes";
import extractOrganizationIdFromRoute from "../utils/extractOrganizationIdFromRoute";
import canUserAccessOrganizationOrGroup from "./canUserAccessOrganizationOrGroup";
import attachOrgToUserAuthState from "./attachOrgToUserAuthState";

// Cache for storing permission results
const permissionCache = new Map();

// Generate a unique key for caching
const generateCacheKey = (userId, organizationId, groupId, routeName) => `${userId}:${organizationId}:${groupId || "no-group"}:${routeName}`;

// Clear cache for a specific user
export const clearUserPermissionCache = (userId) => {
  permissionCache.forEach((_, key) => {
    if (key.startsWith(userId)) {
      permissionCache.delete(key);
    }
  });
};

const checkIfGoingToOrganizationSelection = (route) => isUserInGlobalApp
  && (
    route.name === RouteNames.OrganizationSelection
    || route.query?.redirect?.includes(`/${RouteNames.OrganizationSelection}`)
    || (!route.query?.redirect && !route.params.organizationId)
  );

export const checkIfGeneralAppRoute = (route) => route.name === RouteNames.Admin
  || route.name === RouteNames.Registration
  || route.name === RouteNames.ChangePassword
  || route.name === RouteNames.EmailVerification;

const shouldSkipPermissionCheck = (user, incommingRoute) => user.email === adminEmail
  || checkIfGoingToOrganizationSelection(incommingRoute)
  || checkIfGeneralAppRoute(incommingRoute);

const canUserAccessPage = async (user, incommingRoute) => {
  try {
    // Quick return for routes that don't need permission checks
    if (shouldSkipPermissionCheck(user, incommingRoute)) {
      return { hasAccess: true };
    }

    const generalAppStateStoreInstance = generalAppStartStore();
    let organizationId = await generalAppStateStoreInstance.getOrganizationId();

    if (isUserInGlobalApp && !organizationId) {
      organizationId = extractOrganizationIdFromRoute(incommingRoute.query?.redirect || incommingRoute.fullPath);
    }

    if (!organizationId) {
      const db = getDatabase();
      const dbReference = dbRef(db);
      const organizationNameId = getSubDomain();
      organizationId = (await get(child(dbReference, `nameIdsToOrganizationMap/${organizationNameId}`))).val();
    }

    if (organizationId === personalAppOrganizationId) {
      return { hasAccess: true };
    }

    const { groupId } = incommingRoute.params;
    const cacheKey = generateCacheKey(user.uid, organizationId, groupId, incommingRoute.name);

    // Check cache first
    if (permissionCache.has(cacheKey)) {
      return permissionCache.get(cacheKey);
    }

    // If not in cache, perform the check
    const result = await canUserAccessOrganizationOrGroup(organizationId, user.uid, groupId);

    // Check if any cached permission exists for this org/user combination
    const userHasOrgAlreadyAttached = Array.from(permissionCache.keys()).some((key) => key.startsWith(`${user.uid}:${organizationId}:`));

    if (result.hasAccess && !userHasOrgAlreadyAttached) {
      await attachOrgToUserAuthState(organizationId, user.uid);
    }

    // Cache the result
    permissionCache.set(cacheKey, result);

    return result;
  } catch (error) {
    return { hasAccess: false };
  }
};

export default canUserAccessPage;
