/* eslint-disable prefer-destructuring */

// Helper to detect OS
const getOperatingSystem = () => {
  const { userAgent } = window.navigator;
  const { platform } = window.navigator;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];

  if (macosPlatforms.indexOf(platform) !== -1) {
    return "macos";
  } if (iosPlatforms.indexOf(platform) !== -1) {
    return "ios";
  } if (windowsPlatforms.indexOf(platform) !== -1) {
    return "windows";
  } if (/Android/.test(userAgent)) {
    return "android";
  } if (/Linux/.test(platform)) {
    return "linux";
  }

  return "unknown";
};

const getEnvironmentTags = () => {
  const tags = {
    platform: "web", // Default to web
    // Detect if running in mobile browser
    isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
  };

  // Get browser info
  const { userAgent } = navigator;
  let browser = "unknown";
  let browserVersion = "unknown";

  // Detect browser and version
  if (userAgent.includes("Firefox/")) {
    browser = "firefox";
    browserVersion = userAgent.split("Firefox/")[1].split(" ")[0];
  } else if (userAgent.includes("Chrome/")) {
    browser = "chrome";
    browserVersion = userAgent.split("Chrome/")[1].split(" ")[0];
  } else if (userAgent.includes("Safari/")) {
    browser = "safari";
    browserVersion = userAgent.split("Version/")[1]?.split(" ")[0] || "unknown";
  } else if (userAgent.includes("Edge/")) {
    browser = "edge";
    browserVersion = userAgent.split("Edge/")[1].split(" ")[0];
  }

  return {
    platform: tags.isMobile ? "mobile" : "desktop",
    browser,
    browserVersion,
    screenSize: `${window.innerWidth}x${window.innerHeight}`,
    devicePixelRatio: window.devicePixelRatio,
    os: getOperatingSystem(),
    language: navigator.language,
  };
};

export default getEnvironmentTags;
