/* eslint-disable import/prefer-default-export */

export const categorizeError = (error) => {
  // Check for fetch/network errors
  if (
    (
      error instanceof TypeError // Fetch API network errors are TypeError
      && error.message === "Failed to fetch"
    )
    || error.message === "Network request failed" // React Native
    || error.message.includes("Network Error") // Axios
  ) {
    return "network";
  }

  // Check for timeout
  if (
    error.code === "ECONNABORTED" // Axios timeout
    || error.message.includes("timeout")
    || error.message.includes("Timeout")
  ) {
    return "network_timeout";
  }

  // Check for offline status
  if (!navigator.onLine) {
    return "offline";
  }

  // Common HTTP error status codes for network issues
  const networkHttpCodes = [
    408, // Request Timeout
    502, // Bad Gateway
    503, // Service Unavailable
    504, // Gateway Timeout
  ];

  // Check HTTP response status (works with Axios/Fetch)
  if (error.response?.status && networkHttpCodes.includes(error.response.status)) {
    return "network_http";
  }

  // Firebase specific network errors
  if (
    error.code === "auth/network-request-failed"
    || error.code === "storage/retry-limit-exceeded"
    || error.code?.includes("unavailable")
  ) {
    return "network_firebase";
  }

  // GraphQL network errors
  if (
    error.networkError // Apollo Client
    || error.message?.includes("Network request failed")
  ) {
    return "network_graphql";
  }

  // WebSocket connection errors
  if (
    error.message?.includes("WebSocket")
    || error.message?.includes("socket")
  ) {
    return "network_websocket";
  }

  // Add more categories as needed
  if (error.response?.status === 401 || error.code === "auth/unauthorized") {
    return "auth";
  }
  if (error.response?.status === 403) {
    return "forbidden";
  }
  if (error.response?.status === 404) {
    return "not_found";
  }
  if (error.response?.status >= 500) {
    return "server";
  }

  return "unknown";
};
