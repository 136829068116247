import { createI18n } from "vue-i18n";
import translations from "./translations";

export const locales = {
  cs: "cs-CZ",
  en: "en-US",
};

export const i18n = createI18n({
  locale: localStorage.getItem("locale") || navigator.language.split("-")[0] || "cs", // set locale
  fallbackLocale: "en", // set fallback locale
  messages: translations, // set locale messages
  mode: "composition",
  global: true,
  legacy: false,
});
