import sideMenu from "./sideMenu";
import profileDetail from "./profileDetail";
import coach from "./coach";
import conversationFinishBanner from "./conversationFinishBanner";
import analysisLoader from "./analysisLoader";
import onboarding from "./onboarding";
import paymentModal from "./paymentModal";
import support from "./support";

export default {
  sideMenu,
  profileDetail,
  coach,
  conversationFinishBanner,
  analysisLoader,
  onboarding,
  paymentModal,
  support,
};
