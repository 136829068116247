<template>
  <section class="mx-auto">
    <RouterView />

    <Notifications />
  </section>
</template>

<script setup>
import { onMounted } from "vue";
import initFirebase from "./initFirebase";
import Notifications from "./components/Notifications.vue";

onMounted(initFirebase);
</script>
