export default {
  baseInfo: "Základní informace",
  name: "Jméno",
  unknownName: "Neznámé jméno",
  goal: "Cvičení",
  unknownGoal: "Neznámý cíl",
  user: "Uživatel",
  ai: "AI",
  score: "skóre",
  observableBehaviours: "Tvé chování v oblasti",
  recommendations: "Doporučení",
  analysis: "Analýza",
  conversationRating: "Hodnocení vaší konverzace",
  yourConversation: "Vaše konverzace",
  yourAnalysis: "Vaše analýza",
  fileName: "Analýza konverzace",
  yourJourneyAnalysis: "Analýza vaší lekce",
  journeyFileName: "Analýza lekce",
  notes: "Poznámky a další kroky",
};
