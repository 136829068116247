export default {
  buttons: {
    download: "Stáhnout analýzu",
    reGenerate: "Vygenerovat znovu",
    backToProfileSelection: "Zpět na výběr hry",
    backToGymSelection: "Zpět na výběr posilovny",
    repeatGym: "Opakovat posilovnu",
    repeatConversation: "Opakovat konverzaci",
  },
  titles: {
    yourLevel: "Tvůj level",
    yourBehavious: "Tvé chování v oblasti",
    recommendations: "Doporučení",
    noAnalysisFound: "Žádnou analýzu jsme nenašli. Zkuste si konverzaci opakovat.",
    yourAnalysis: "Vaše analýza",
  },
  levels: {
    amateur: "Začátečník",
    intermediate: "Pokročilý",
    expert: "Expert",
  },
};
