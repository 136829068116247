import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
  reauthenticateWithCredential,
  EmailAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  signInWithPopup,
  getAdditionalUserInfo,
} from "firebase/auth";
import {
  getDatabase, ref as dbRef, set,
} from "firebase/database";
import { i18n } from "../../i18n";
import getAuthenticatedUser from "./getAuthenticatedUser";
import notificationsStore, { NotificationTypes } from "../../stores/notifications";
import sendRegistrationMail from "./sendRegistrationMail";
import sanitizeObject from "../utils/sanitizeObject";
import { correctUsersRoute } from "../../constants/dbRoutes";
import addNewUserCredits from "./addNewUserCredits";
import errorFeatureIds from "../../constants/errorFeatureIds";
import { handleErrorTracking } from "../../middleWare/errorTracker";

const providersMap = {
  google: GoogleAuthProvider,
  facebook: FacebookAuthProvider,
  apple: OAuthProvider,
  email: EmailAuthProvider,
};

const createConstructorInstance = (provider) => new providersMap[provider]();

export default handleErrorTracking(
  async (
    loginEmail,
    loginPassword,
    provider = "email",
  ) => {
    const userLoggingInWithEmail = provider === "email";
    const notificationsStoreInstance = notificationsStore();
    const auth = getAuth();
    const providerInstance = userLoggingInWithEmail ? providersMap[provider] : createConstructorInstance(provider);

    await setPersistence(auth, browserSessionPersistence);

    const userCredentials = await (userLoggingInWithEmail ? signInWithEmailAndPassword(
      auth,
      loginEmail,
      loginPassword,
    ) : signInWithPopup(auth, providerInstance));
    const {
      user: {
        displayName, email, uid, // emailVerified,
      },
    } = userCredentials;

    // if (!emailVerified && email !== adminEmail && isUserInGlobalApp) {
    //   notificationsStoreInstance.addNotification({
    //     title: i18n.global.t("others.errors.messages.emailNotVerified"),
    //     type: NotificationTypes.Error,
    //     id: "email-not-verified",
    //     action: {
    //       title: i18n.global.t("others.errors.buttons.reSendVerificationMail"),
    //       click: async () => {
    //         notificationsStoreInstance.removeNotification("email-not-verified");

    //         await sendVerificationMail({
    //           user: {
    //             email,
    //           },
    //           language: i18n.global.locale.value,
    //         });

    //         notificationsStoreInstance.addNotification({
    //           title: i18n.global.t("views.logIn.messages.verificationEmail"),
    //           type: NotificationTypes.Success,
    //           permanent: true,
    //         });
    //       },
    //     },
    //   });

    //   return null;
    // }

    const { isNewUser } = getAdditionalUserInfo(userCredentials);

    if (isNewUser) {
      const data = sanitizeObject({
        email: email || "",
        firstName: displayName || "",
        id: uid,
        createdAt: new Date().toISOString(),
      });

      const db = getDatabase();

      await set(dbRef(db, `${correctUsersRoute}/${uid}`), data);
      await sendRegistrationMail({
        user: data,
        language: i18n.global.locale.value,
      });

      // TODO rework this
      addNewUserCredits(uid);

      notificationsStoreInstance.addNotification({
        title: i18n.global.t("views.logIn.messages.registrationSuccess"),
        type: NotificationTypes.Success,
      });
    }

    if (userLoggingInWithEmail && userCredentials.user) {
      // Re-authenticate user every 1 hour
      setInterval(async () => {
        const credential = providerInstance.credential(
          loginEmail,
          loginPassword,
        );
        const { user: reauthenticatedUser } = await reauthenticateWithCredential(getAuthenticatedUser(), credential);

        if (reauthenticatedUser) {
          reauthenticatedUser.getIdToken(true).then((token) => {
            window.sessionStorage.setItem("token", token);
          });
        } else {
          window.location.reload();
        }
      }, 3600000);
    }

    return {
      displayName,
      email,
      uid: userCredentials.user.uid,
    };
  },
  {
    feature: errorFeatureIds.auth.methods.logInUser,
    measurePerformance: true,
    notification: {
      isFirebaseError: true,
    },
  },
);
