export default {
  chooseGame: "Game selection",
  chooseGym: "Gym selection",
  chooseMicrolearning: "Microlearning selection",
  learningPaths: "Lessons",
  logOut: "Log out",
  support: "Support",
  termsOfUse: "Terms of Use",
  remainingCreditPercentage: "remaining credits",
  switchOrganization: "Switch organization",
  profile: "Profile",
  history: "History",
  adminDashboard: "Admin dashboard",
};
