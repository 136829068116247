export default {
  title: "Your Profile",
  changeInfo: "Change Info",
  credits: "Credits",
  creditsAmount: "My credits",
  minNumberOfCredits: "Minimum number of credits is {minNumberOfCredits}",
  buttons: {
    buyCredits: "Buy More Credits",
    update: "Update",
  },
  labels: {
    firstName: "First Name",
    lastName: "Last Name",
    addCredits: "Add Credits",
  },
  placeholders: {
    addCredits: "Amount of credits to add, minimum is {minNumberOfCredits}",
  },
  messages: {
    creditsAdded: "Credits have been successfully added.",
    creditsNotAdded: "Credits could not be added.",
    updateSuccess: "Profile updated successfully.",
    noChanges: "No changes were made.",
  },
};
