export default {
  description: `Ahoj! Jsem Provocator.ai. Pomůžu ti zlepšit se ve všech monžých komunikačních dovednostech, které využiješ jak ve svém profesionálním, tak osobním životě.

  Pojďme na to!`,
  disclaimerDescription: `I když se vžiješ do role, dej si pozor na to, co říkáš nebo píšeš – ne vše je při komunikaci s AI trenérem dovoleno.
  Určitě nesděluj ničí osobní údaje, údaje podléhající mlčenlivosti nebo obchodnímu tajemství. Nesděluj obsah, který může jednotlivci nebo společnosti způsobit jakoukoliv újmu.
  <br/>
  Všechny výstupy jsou generovány AI, a ta má své limity. Ty máš něco, co AI zatím nemá – vědomí a rozum.
  Přistupuj proto k výstupům AI s kritickým uvažováním. Používáním aplikace souhlasíš s přijímáním marketingových emailů. Můžeš se kdykoliv odhlásit v rámci merketingového emailu.
  Tím, že aplikaci spustíš, souhlasíš s dodržováním požadavků na obsah a limitů výstupů, které si můžeš přečíst
  <a href="https://www.provocator.ai/terms-of-use" rel="noopener noreferrer" class="underline" target="_blank">TADY</a>.`,
  slideTitles: {
    0: "Vítej v Provocator.ai",
    1: "Rychlý průvodce",
    2: "Vyber si lekci.",
    3: "Nejdřív dostaneš pár tipů jak na to.",
    4: "Pojď rovnou trénovat! Čekají tě praktická cvičení.",
    5: "Nech si dát zpětnou vazbu a zkus to znovu.",
    6: "Zahraješ modelovou situaci a zjistíš, jak na tom jsi.",
    7: "Nevíš, jak v rozhovoru dál? Zeptej se AI kouče.",
  },
  buttons: {
    next: "Další",
    play: "Hrát",
    start: "Začít",
    skip: "Přeskočit",
    agree: "Souhlasím, pojďme hrát!",
  },
};
