// eslint-disable-next-line import/no-unresolved
import { VueRecaptchaPlugin } from "vue-recaptcha/head";
import { createApp } from "vue";
import { createPinia } from "pinia";
import VueFroala from "vue-froala-wysiwyg";
import * as Sentry from "@sentry/vue";
import { i18n } from "./i18n";
import "./index.css";
import "flag-icons/css/flag-icons.min.css";
// Froala WYSIWYG Editor
import "froala-editor/js/plugins.pkgd.min";
import "froala-editor/js/third_party/embedly.min";
import "froala-editor/js/third_party/font_awesome.min";
import "froala-editor/js/third_party/spell_checker.min";
import "froala-editor/js/third_party/image_tui.min";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";

import App from "./App.vue";
import router from "./router";

import "./assets/main.css";

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://4f97737bfba7616af8d4372b1c740c0a@o4508222651891712.ingest.de.sentry.io/4508222653399120",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // Local development
    "app.provocateur.localhost:5173",
    // Production
    "app.provocator.ai",
    // You can also include your API endpoints if they're on different domains
    /^https?:\/\/api\.provocator\.ai/,
    // Include all subdomains of provocator.ai
    /^https?:\/\/.*\.provocator\.ai/,
  ],

  // Session Replay
  // Set different trace sample rates for development and production
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.3 : 1.0,
  trackComponents: true,
  replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.3 : 1.0,
  replaysOnErrorSampleRate: 1.0,

  // Environment-specific configurations
  environment: process.env.NODE_ENV,

  // Enable debug mode in development
  debug: false,
});

const piniaStore = createPinia();

piniaStore.use(Sentry.createSentryPiniaPlugin());

app.use(VueRecaptchaPlugin, {
  v3SiteKey: "6LcpxMopAAAAAOqobQVAqNeeK8_Z59s62UkKyMIL",
});
app.use(piniaStore);
app.use(router);
app.use(i18n);
app.use(VueFroala);
app.mount("#app");
