import correctEndpointUrl from "../../constants/firebaseEndpoint";

export default async (organizationId, groupId) => {
  const response = await fetch(`${correctEndpointUrl}/getOrganizationModulesState`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify({ organizationId, groupId }),
  });

  return response.json();
};
