export default {
  messages: {
    notEnoughCredits: "You don't have enough credits to perform this action. Please add credits to your account.",
    unableToAnalyzeConversation: "We were unable to analyze the conversation.",
    unableToAnalyzeJourney: "We were unable to analyze the lesson.",
    inappropriateContent: "Your message or messages were inappropriate.",
    responseTooLong: "The response was too long.",
    responseSlow: "The response was too slow.",
    serverError: "There was an error on the server.",
    serverErrorUpdatingCredits: "There was an error updating member credits.",
    maxMessagesReached: "The maximum number of messages has been reached.",
    gameEnded: "The game has already ended, you cannot continue the conversation.",
    nothingToAnalyze: "You have no messages to analyze.",
    generalChatError: "There was an error sending the message. Try sending the message again, or try shortening it.",
    tokenDelay: "The server stopped responding. Try sending the message again, or reload the page.",
    emailNotVerified: "Your email has not yet been verified. Please verify your email and try again.",
    paymentsSessionCreationFailed: "Failed to create a payment session. Please try again.",
    minCreditAmountNotReached: "The minimum credit amount of {minNumberOfCredits} has not been reached.",
    notEnoughCreditsToStartLesson: "You don't have enough credits to start this lesson.",
    userNotFound: "User not found.",
    journeyNotFound: "Lesson not found.",
    journeyEnded: "The lesson has already ended, you cannot continue the exercise.",
    journeyStepsNotFound: "The lesson was not found.",
    unableToGenerateJourneyAnalysis: "We do not have enough information to generate a lesson analysis.",
    unableToLoadOrganization: "We were unable to load the organization data.",
    invalidInput: "Invalid input",
    unableToFetchLearningPaths: "Failed to load lessons, please refresh the page.",
    logoutIssue: "Logout failed. Please try again.",
    unknownError: "An unknown error occurred.",
    gameLoadIssue: "Failed to load the game. Please try again.",
    organizationModulesIssue: "Failed to load organization modules. Please try again.",
    unableToLoadMicrolearnings: "Failed to load microlearnings, please refresh the page.",
    unableToLoadGym: "Failed to load exercise, please refresh the page.",
    unableToLoadGyms: "Failed to load exercises, please refresh the page.",
    unableToStreamRespone: "Failed to get response.",
    unableToGetAudioResponse: "Failed to get audio response.",
    unableToFetchProfile: "Failed to load games, please refresh the page.",
    unableToStartLesson: "Failed to start lesson, please refresh the page.",
    unableToLoadLearningPath: "Failed to load lesson, please refresh the page.",
    unableToStartGame: "Failed to start game.",
    unableToEndJourney: "Failed to end lesson.",
  },
  buttons: {
    reSendVerificationMail: "Resend verification email",
  },
  user: {
    noAccessToOrganization: "You don't have access to this organization.",
  },
  firebase: {
    "auth/invalid-credential": "Invalid email or password.",
    "auth/user-not-found": "User not found.",
    "auth/wrong-password": "Invalid password.",
    "auth/too-many-requests": "Too many requests. Please try again later.",
    "auth/email-already-in-use": "This email is already in use.",
    "auth/invalid-email": "Invalid email.",
    "auth/operation-not-allowed": "This operation is not allowed.",
    "auth/popup-blocked": "The login window was blocked by your browser.",
    "auth/quota-exceeded": "You have exceeded the allowed number of login attempts.",
  },
};
