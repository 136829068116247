import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { ref, reactive } from "vue";

/**
 * @typedef {Object} Notification
 * @prop {string} id
 * @prop {string} title
 * @prop {string} message
 * @prop {number} timeout
 * @prop {('Info' | 'Warning' | 'Error' | 'Success')} type
 * @prop {boolean} permanent
 * @prop {{ title: string; click: () => void }} action
 */

/**
 * @typedef {Object} CreateNotificationParams
 * @prop {string} title
 * @prop {?string} message
 * @prop {?number} timeout
 * @prop {?('Info' | 'Warning' | 'Error' | 'Success')} type
 * @prop {?boolean} permanent
 * @prop {?{ title: string; click: () => void }} action
 */

export const NotificationTypes = {
  Info: "Info",
  Warning: "Warning",
  Error: "Error",
  Success: "Success",
};

export default defineStore("notifications", () => {
  /** @type {Ref<Notification[]>} */
  const notifications = ref([]);

  /** @param {string} id */
  const removeNotification = (id) => {
    const notificationToBeRemovedIndex = notifications.value.findIndex((notification) => notification.id === id);

    if (notificationToBeRemovedIndex !== -1) {
      notifications.value.splice(notificationToBeRemovedIndex, 1);
    }
  };

  /**
   * @param {CreateNotificationParams} params
   * @returns {string}
   */
  const addNotification = ({
    id = uuidv4(),
    timeout = 10000,
    type = NotificationTypes.Info,
    permanent = false,
    ...notification
  }) => {
    /** @type {Notification} */
    const newNotification = reactive({
      id,
      timeout,
      type,
      ...notification,
      message: notification.message || "",
    });

    notifications.value.unshift(newNotification);

    // Set timout for auto remove (if timeout is 0, keep it)
    if (timeout > 0 && !permanent) setTimeout(() => removeNotification(newNotification.id), newNotification.timeout);

    return newNotification.id;
  };

  return {
    notifications,
    removeNotification,
    addNotification,
  };
});
